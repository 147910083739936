import "./footer.scss";
import { useContext, useEffect, useRef, useState } from "react";
import { JWTResponseDTO, UserDTO } from "../../Models/UserManagementDto";
import { LOCAL_STORAGE, SERVER } from "../../Helpers/constants";
import { CLOG, showToast, TOAST } from "../../Helpers/ui-helper";
import { PoolRequestDTO } from "../../Models/PoolsDTO";
import { AppContext } from "../../Context/appContext";
import { getLocalStorageObject, getTrackName } from "../../Helpers/valueHelper";
import PoolReqNotification from "../../Pages/Components/PoolReqNotification/poolReqNotification";
import { ScheduleApiService } from "../../services/scheduleApiService";
import NextRaces from "../NextRaces/NextRaces";

function Footer() {
  const { user } = useContext(AppContext);

  const wsNotification = useRef<WebSocket | null>(null);
  const requestNotificationList = useRef<PoolRequestDTO[]>([]);
  const [waitingToReconnect, setWaitingToReconnect] = useState<Boolean>(true);
  const [requests, setRequests] = useState<PoolRequestDTO[]>([]);

  useEffect(() => {
    let client: WebSocket | null = null;
    console.log("FOOOTER EFFECT CALLED");

    const listenFromSocket = () => {
      if (!waitingToReconnect) return;
      if (!wsNotification.current) {
        let jwtData: JWTResponseDTO = getLocalStorageObject(LOCAL_STORAGE.JWT);
        if (!jwtData || !jwtData.token) {
          return;
        }

        let userData: UserDTO = getLocalStorageObject(LOCAL_STORAGE.USER);
        if (!userData || !userData.uid) {
          return;
        }

        let wsURL =
          SERVER.WS_URL +
          "notifications?userId=" +
          userData.uid +
          "&token=" +
          jwtData.token;

        client = new WebSocket(wsURL);
        wsNotification.current = client;
        wsNotification.current.onopen = () => {
          CLOG(`___WSO____NOTIFICATION SOCKET OPEN`, "green");
          getPoolRequested();
        };

        wsNotification.current.onmessage = (socketMessage: any) => {
          if (socketMessage?.data) {
            let message = JSON.parse(socketMessage.data);

            switch (message?.messageType) {
              case "MANAGED_REQ":
                managePoolRequest(message.msg);
                break;
              default:
                break;
            }
          }
        };
        wsNotification.current.onclose = () => {
          if (wsNotification.current) {
            CLOG(`___WSO____NOTIFICATION SOCKET CLOSED BY SERVER`, "red");
          } else {
            setWaitingToReconnect(false);
            // Cleanup initiated from app side, can return here, to not attempt a reconnect
            CLOG(
              `___WSO____NOTIFICATION SOCKET CLOSED BY APP COMPONENT`,
              "red"
            );
            return;
          }

          // if (waitingToReconnect) {
          //   return;
          // };

          // Setting this will trigger a re-run of the effect,
          // cleaning up the current websocket, but not setting
          // up a new one right away
          setWaitingToReconnect(false);

          // This will trigger another re-run, and because it is false,
          // the socket will be set up again
          setTimeout(() => setWaitingToReconnect(true), 500);
        };
      }
    };
    listenFromSocket();

    return () => {
      CLOG(`___WSO____NOTIFICATION SOCKET CLEAN UP `, "gray");
      // Dereference, so it will set up next time
      wsNotification.current = null;
      if (client) {
        console.log("CLIENT IS CLOSED FOR NOTIFICATION SOCKET");
        client?.close();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waitingToReconnect]);

  const getPoolRequested = () => {
    ScheduleApiService.getAllUsersPoolReq()
      .then((res) => {
        if (res && res.data) {
          for (const reqObj of res.data) {
            if (reqObj.currentUserId === user.uid) {
              requestNotificationList.current.push(reqObj);
            }
          }
          setRequests([...requestNotificationList.current]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const managePoolRequest = (req: PoolRequestDTO) => {
    if (req.requestedUserId === user.uid) {
      if (req.requestStatus === "ACCEPTED") {
        return showToast(
          "In track " +
            getTrackName(req.poolKey.pzoneCode) +
            " race No " +
            req.poolKey.raceNo +
            "  request is accepted for " +
            req.poolKey.poolType.toLocaleLowerCase(),
          TOAST.SUCCESS
        );
      }
      if (req.requestStatus === "REJECTED") {
        return showToast(
          "In track " +
            getTrackName(req.poolKey.pzoneCode) +
            " race No " +
            req.poolKey.raceNo +
            "  request is rejected for " +
            req.poolKey.poolType.toLocaleLowerCase(),
          TOAST.WARNING
        );
      }
    } else if (
      req.currentUserId === user.uid &&
      req.requestStatus === "PENDING"
    ) {
      requestNotificationList.current.push(req);
      setRequests([...requestNotificationList.current]);
    } else if (req.currentUserId === user.uid) {
      deleteReqObj(req.reqId);
      if (req.requestStatus === "ACCEPTED") {
        return showToast(
          "Track " +
            getTrackName(req.poolKey.pzoneCode) +
            "  race No " +
            req.poolKey.raceNo +
            "  is accepted the request for  " +
            req.poolKey.poolType.toLocaleLowerCase(),
          TOAST.SUCCESS
        );
      }
      if (req.requestStatus === "REJECTED") {
        return showToast(
          "Track " +
            getTrackName(req.poolKey.pzoneCode) +
            "  race No " +
            req.poolKey.raceNo +
            "  is rejected the request for  " +
            req.poolKey.poolType.toLocaleLowerCase(),
          TOAST.WARNING
        );
      }
    }
  };

  const deleteReqObj = (reqId: string) => {
    let index = requestNotificationList.current.findIndex(
      (x) => x.reqId === reqId
    );
    if (index !== -1) {
      requestNotificationList.current.splice(index, 1);
      setRequests([...requestNotificationList.current]);
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <PoolReqNotification
        requestedNotifications={requests}
        deleteReqObj={deleteReqObj}
      />

      <div className="footer-container">
        <div className="footer-content">
          <div className="copyrightWrapper">
            <a
              href="https://www.protocolzone.com"
              rel="noreferrer"
              target="_blank"
            >
              &copy; ProtocolZone pvt. ltd.
            </a>
            <span style={{ paddingLeft: 0 }}>version - 0.0.1</span>
          </div>
          <NextRaces />
        </div>
      </div>
    </div>
  );
}
export default Footer;
