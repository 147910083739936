import { useEffect } from "react";
import { getRaceKeyStr, getTrackName } from "../../../Helpers/valueHelper";
import { RaceDetailsDTO } from "../../../Models/RaceDetailsDTO";
import "./displayTrackRaces.scss";
import warning from "../../../assets/images/warning-icon.svg";
import { useRef } from "react";
import { LOCAL_STORAGE } from "../../../Helpers/constants";

export interface IProps {
  onRaceClick: any;
  raceConnectorInfo: any;
  selectedRaces: any;
  selectedRace: any;
}

export default function DisplayTrackRaces(props: IProps) {
  let _races = Object.values(props.selectedRaces ?? {}) as RaceDetailsDTO[];

  const scrollref = useRef({} as any);

  useEffect(() => {
    if (_races.length !== 0) {
      let isValidRaceFound: boolean = false;
      let rKey = localStorage.getItem(LOCAL_STORAGE.SELECTEDRACEKEY);

      for (const race of _races) {
        if (rKey && race.raceKeyString === getRaceKeyStr(JSON.parse(rKey))) {
          isValidRaceFound = true;
          scrollRaceIsSelected(race);
          props.onRaceClick(race);
          break;
        }
      }

      if (isValidRaceFound) {
        return;
      }
      for (const race of _races) {
        if (race.raceStatus === "NON_DISPLAY" || race.raceStatus === "OPEN") {
          isValidRaceFound = true;
          scrollRaceIsSelected(race);
          race.raceKeyString = getRaceKeyStr(race.raceKey);
          props.onRaceClick(race);
          break;
        }
      }
      if (!isValidRaceFound) {
        if (_races.length) {
          scrollRaceIsSelected(_races[_races.length - 1]);
          props.onRaceClick(_races[_races.length - 1]);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedRaces]);

  const scrollRaceIsSelected = (_race: any) => {
    if (scrollref?.current) {
      scrollref.current[_race?.raceKeyString]?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  };

  const onRaceSelect = (race: RaceDetailsDTO) => {
    localStorage.removeItem(LOCAL_STORAGE.SELECTEDRACEKEY);
    props.onRaceClick(race);
  };

  const warningTemplate = (raceKey: string) => {
    if (
      props.raceConnectorInfo[raceKey] &&
      props.raceConnectorInfo[raceKey].status !== "CONNECTED"
    )
      return (
        <img
          src={warning}
          style={{ height: 30, width: 30 }}
          alt="Connector Disconnected"
          title={`Connector is ${props.raceConnectorInfo[raceKey].status}`}
        />
      );
  };

  return (
    <div className="selected-Track-Race-container">
      {_races &&
        _races.map((race) => {
          return (
            <div
              className={
                props.selectedRace?.raceKeyString === race.raceKeyString
                  ? "active-track-race-card"
                  : "track-race-card"
              }
              onClick={() => onRaceSelect(race)}
              key={getRaceKeyStr(race.raceKey)}
              ref={(prev) => (scrollref.current[race.raceKeyString] = prev)}
            >
              <div className="d-row d-flex flex-column">
                <div className="d-flex justify-content-between">
                  <span className="trackName p-1">
                    R{race.raceKey.raceNo} -{" "}
                    {getTrackName(race.raceKey.pzoneCode)}
                  </span>
                  {warningTemplate(race.raceKeyString)}
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span className="postTime p-1">
                    {race.formattedPosttime
                      ? race.formattedPosttime
                      : "No post-time available!"}
                  </span>
                  <span className="raceStatus">
                    {race.foGenerated === true && (
                      <span className="fo-badge">FO</span>
                    )}
                    {race.cancelled ? (
                      <>
                        {race.cancelled && (
                          <div className="cancelled_badge">Cancelled</div>
                        )}
                      </>
                    ) : (
                      <span
                        className={`race-status ${race.raceStatus.toLowerCase()}`}
                      >
                        {race.raceStatus.replace(/_/, " ")}
                      </span>
                    )}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      {_races && !_races.length && (
        <h3 className="text-muted text-center p-3">No Races Found</h3>
      )}
    </div>
  );
}
