import "./App.scss";
import "./theme.scss";
import { Route, Switch, useHistory } from "react-router-dom";
import { ROUTES, LOCAL_STORAGE } from "./Helpers/constants";
import LoginPage from "./Pages/Authentication/Login/LoginPage";
import UserAccessManagement from "./Pages/UserAccessManagement/userAccessManagement";
import SchedulesManagement from "./Pages/SchedulesManagement/schedulesManagement";
import {
  JWTResponseDTO,
  UserDetailsDTO,
  UserDTO,
} from "./Models/UserManagementDto";
import { useEffect, useRef, useState } from "react";
import { getLocalStorageObject, setLocalStorage } from "./Helpers/valueHelper";
import { ToastContainer } from "react-toastify";
import { AppContextDTO, AppContextProvider } from "./Context/appContext";
import { UserApiService } from "./services/userApiService";
import { CLOG, showToast, TOAST } from "./Helpers/ui-helper";
import { format } from "date-fns";
import RulesManagmentPage from "./Pages/RulesManagement/rulesManagement";
import CustomerBetHistoryData from "./Pages/TransactionMangement/components/CustomerBetHistoryData/CustomerBetHistoryData";
import WagerData from "./Pages/TransactionMangement/components/BetHistoryData/WagerHistoryData";
import { getRoleBaseRoute } from "./Helpers/routingHelper";
import Footer from "./UI-Components/Footer/footer";
import { RulesService } from "./services/rulesApiService";
import { PrimeItem } from "./Helpers/StaticData/race-data";
import { ConnectorDTO } from "./Models/temp";
import { PoolRequestDTO } from "./Models/PoolsDTO";
import Connector from "./Pages/Connectors/connectors";

import ActiveCustomer from "./Pages/CustomerSummary/Screens/ActiveCustomer/ActiveCustomer";
import SearchCustomer from "./Pages/CustomerSummary/Screens/SearchCustomer/SearchCustomer";
import SearchCustomerReport from "./Pages/CustomerSummary/Screens/SearchCustomerReport/SearchCustomerReport";
import GenericNotFound from "./Pages/genericNotFound";
import {
  hasConnectorPermission,
  hasCustomerSummaryPermission,
  hasLogsPermission,
  hasReportPermission,
  hasRiskPermission,
  hasRulesPermission,
  hasSchedulePermission,
  hasTotePermission,
  hasUsersAccess,
} from "./Helpers/permission-helpers";
import FixedOddsManagement from "./Pages/FixedOddsManagement/FixedOddsManagement";
import RaceLogs from "./Pages/TransactionMangement/components/RaceLogs/RaceLogs";
import RaceTripLogs from "./Pages/TransactionMangement/components/RaceTripLogs/RaceTripLogs";
import PoolLogs from "./Pages/TransactionMangement/components/PoolLogs/PoolLogs";
import WagersSummary from "./Pages/TransactionMangement/components/WagersSummary/wagersSummary";
import ToteManagement from "./Pages/ToteManagement/toteManagement";
import { RecoilRoot } from "recoil";
import RacesProvider from "./provider/races.provider";

// import { PoolRequestDTO } from "./Models/PoolsDTO";

// var isSocketConnect: boolean = false;
// var ws: WebSocket | null = null;

function App() {
  const history = useHistory();
  const [appContext, setAppContext] = useState<AppContextDTO>({} as any);
  const tokenInterval: any = useRef();
  const [hasRefreshToken, setHasRefreshToken] = useState(false);

  useEffect(() => {
    const getLoggedInUser = () => {
      let user: UserDTO = getLocalStorageObject(LOCAL_STORAGE.USER);
      let jwt: JWTResponseDTO = getLocalStorageObject(LOCAL_STORAGE.JWT);

      if (user && jwt) {
        let route = getRoleBaseRoute(user.roles);
        if (route) {
          let appCtxt = { ...appContext } as AppContextDTO;
          appCtxt.user = user;
          appCtxt.jwtResponse = jwt;
          refreshAuthToken();
          startTokenInterval();

          //FIXME
          if (!appContext.connectorsOptions) {
            getConnectorsOptions(appCtxt).then((ctx) => {
              setAppContext(ctx);
            });
          }

          if (window.location.pathname === ROUTES.LOGIN) history.push(route);
        }
      } else if (window.location.pathname !== ROUTES.LOGIN) {
        history.push(ROUTES.LOGIN);
      }
    };

    if (!appContext.jwtResponse && !appContext.user) getLoggedInUser();
    return () => {
      if (tokenInterval?.current) {
        clearInterval(tokenInterval);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getConnectorsOptions = (ctxt: AppContextDTO) => {
    return new Promise<AppContextDTO>((resolve) => {
      let options = [] as PrimeItem[];
      RulesService.getConnectors()
        .then((res) => {
          CLOG("=>___API___Connectors Options____ ", "purple");
          if (res.data) {
            let connectors = res.data as ConnectorDTO[];
            for (let connector of connectors) {
              options.push({
                label: connector.connectorName,
                value: connector.accountId,
                currency: connector.currency,
              });
            }
            ctxt.connectorsOptions = options;
            resolve(ctxt);
          }
        })
        .catch((err) => {
          resolve(ctxt);
          console.log(err);
        });
    });
  };

  const startTokenInterval = (start?: boolean) => {
    if (window.location.pathname !== ROUTES.LOGIN || start) {
      tokenInterval.current = setInterval(() => {
        refreshAuthToken();
      }, 600000);
    }
  };

  const saveUserAuth = (auth: UserDetailsDTO) => {
    // let route = getRoleBaseRoute(auth.user.roles);
    // if (!route)
    //   return showToast(
    //     "You don't have permission to access any page!",
    //     TOAST.ERROR
    //   );

    let context = { ...appContext } as AppContextDTO;
    context.jwtResponse = auth.jwtResponse;
    context.user = auth.user;
    setLocalStorage(LOCAL_STORAGE.USER, JSON.stringify(auth.user));
    setLocalStorage(LOCAL_STORAGE.JWT, JSON.stringify(auth.jwtResponse));
    getConnectorsOptions(context).then((context) => {
      setAppContext(context);
    });
    // setAppContext(context);
    startTokenInterval(true);
    // startGlobalSocket(auth.jwtResponse, auth.user);
    refreshAuthToken();
    // history.push(ROUTES.DASHBOARD);
    let route = getRoleBaseRoute(auth.user.roles);
    if (route) {
      history.push(route);
    }
  };

  const refreshAuthToken = () => {
    UserApiService.refreshToken()
      .then((res: any) => {
        if (res && res.data) {
          console.info("> TOKEN REFRESHED...");
          setLocalStorage(LOCAL_STORAGE.JWT, JSON.stringify(res.data));
          setLocalStorage(
            "LAST_TOKEN_UPDATED",
            format(Date.now(), "yyyyMMdd HHmmss")
          );
          setHasRefreshToken(true);
        }
      })
      .catch((err) => {
        console.error(err);
        setHasRefreshToken(false);
        if (err.response) {
          if (err.response.data.statusCode === 401) {
            showToast(
              "Session Expired! Please Login Again!",
              TOAST.ERROR,
              "401"
            );

            return;
          } else if (err.response.data.statusCode === 500)
            showToast(err.response.data.error, TOAST.ERROR, "500");
          else {
            showToast(
              "Session is Expired! Please Login Again!",
              TOAST.ERROR,
              "other"
            );
          }
        } else {
          showToast(
            "Session is Expired! Please Login Again!",
            TOAST.ERROR,
            "other"
          );
        }
        sessionStorage.clear();
        localStorage.clear();
        history.push(ROUTES.LOGIN);
      });
  };

  const clearData = () => {
    sessionStorage.clear();
    localStorage.clear();
    setAppContext({} as AppContextDTO);
  };

  const ProtectedRoute = (props: { path: string; component: any }) => {
    return (
      <Route
        path={props.path}
        exact
        render={() => {
          let user: UserDTO = getLocalStorageObject(LOCAL_STORAGE.USER);
          let jwt = getLocalStorageObject(LOCAL_STORAGE.JWT);
          if (jwt && hasRefreshToken) {
            if (checkValidURL(props.path, user.roles)) {
              return <props.component />;
            } else {
              console.log("Not Permission");
              return <GenericNotFound msg="Permission Denied !" />;
            }
          } else {
            return <LoginPage saveUserAuth={saveUserAuth} />;
          }
        }}
      />
    );
  };

  const checkValidURL = (path: string, roles: string[]) => {
    if (path === ROUTES.SCHEDULE) {
      return hasSchedulePermission(roles);
    } else if (path === ROUTES.TOTE_MANAGEMENT) {
      return hasTotePermission(roles);
    } else if (path === ROUTES.FIXED_ODDS_MANAGEMENT) {
      return hasRiskPermission(roles);
    } else if (path === ROUTES.CONNECTORS) {
      return hasConnectorPermission(roles);
    } else if (path === ROUTES.USER_ACCESS) {
      return hasUsersAccess(roles);
    } else if (path === ROUTES.RULES) {
      return hasRulesPermission(roles);
    } else if (
      path === ROUTES.CUSTOMER_SUMMARY ||
      path === ROUTES.ACTIVE_CUSTOMER ||
      path === ROUTES.SEARCH_CUSTOMER ||
      path === ROUTES.SEARCH_CUSTOMER_REPORT
    ) {
      return hasCustomerSummaryPermission(roles);
    } else if (
      path === ROUTES.REPORTS ||
      path === ROUTES.WAGERSHISTORY ||
      path === ROUTES.CUSTOMERHISTORY ||
      path === ROUTES.WAGERSSUMMARY
    ) {
      return hasReportPermission(roles);
    } else if (
      path === ROUTES.LOGS ||
      path === ROUTES.RACELOGS ||
      path === ROUTES.RACETRIP ||
      path === ROUTES.POOLLOGS
    ) {
      return hasLogsPermission(roles);
    } else if (path === ROUTES.DASHBOARD) {
      return true;
    }
  };

  const updateContext = (context: AppContextDTO) => setAppContext(context);

  const updatePoolRequest = (req: PoolRequestDTO | null) => {
    let context = { ...appContext };
    context.reqObj = req;
    setAppContext(context);
  };

  return (
    <RecoilRoot>
      <div className={`App-container dark layout-1`}>
        <ToastContainer />
        <AppContextProvider
          value={{
            ...appContext,
            updateContext: updateContext,
            clearData: clearData,
            updatePoolRequest: updatePoolRequest,
            getConnectorOptions: getConnectorsOptions,
          }}
        >
          <RacesProvider>

          
          <Switch>
            <Route exact path={ROUTES.LOGIN}>
              <LoginPage saveUserAuth={saveUserAuth} />
            </Route>

            {/* <ProtectedRoute path={ROUTES.DASHBOARD} component={Dashboard} /> */}
            <ProtectedRoute
              path={ROUTES.FIXED_ODDS_MANAGEMENT}
              component={FixedOddsManagement}
            />
            <ProtectedRoute
              path={ROUTES.USER_ACCESS}
              component={UserAccessManagement}
            />
            <ProtectedRoute
              path={ROUTES.SCHEDULE}
              component={SchedulesManagement}
            />
            <ProtectedRoute
              path={ROUTES.TOTE_MANAGEMENT}
              component={ToteManagement}
            />
            <ProtectedRoute
              path={ROUTES.ACTIVE_CUSTOMER}
              component={ActiveCustomer}
            />
            <ProtectedRoute
              path={ROUTES.SEARCH_CUSTOMER}
              component={SearchCustomer}
            />
            <ProtectedRoute
              path={ROUTES.SEARCH_CUSTOMER_REPORT}
              component={SearchCustomerReport}
            />
            <ProtectedRoute
              path={ROUTES.RULES}
              component={RulesManagmentPage}
            />
            <ProtectedRoute path={ROUTES.CONNECTORS} component={Connector} />
            <ProtectedRoute
              path={ROUTES.CUSTOMERHISTORY}
              component={CustomerBetHistoryData}
            />
            <ProtectedRoute path={ROUTES.WAGERSHISTORY} component={WagerData} />
            <ProtectedRoute path={ROUTES.RACELOGS} component={RaceLogs} />
            <ProtectedRoute path={ROUTES.RACETRIP} component={RaceTripLogs} />
            <ProtectedRoute path={ROUTES.POOLLOGS} component={PoolLogs} />
            <ProtectedRoute
              path={ROUTES.WAGERSSUMMARY}
              component={WagersSummary}
            />
            <Route path="*" component={GenericNotFound}></Route>
          </Switch>
           <Footer />
           </RacesProvider>
        </AppContextProvider>
      </div>
    </RecoilRoot>
  );
}

export default App;
