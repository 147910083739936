import { useEffect, useRef, useState } from "react";

import { ColsOdds, TableColumnsDTO } from "../../Helpers/columns";
import Header from "../../UI-Components/Header/header";
import RaceSchedular from "../Components/RaceSchedularComponent/raceSchedular";
import { Tooltip } from "primereact/tooltip";
import { LOCAL_STORAGE, ROUTES, SERVER } from "../../Helpers/constants";
import { RaceDetailsDTO, ResultsDTO } from "../../Models/RaceDetailsDTO";
import { RaceTripDTO } from "../../Models/raceTripsDTO";
import "./FixedOddsManagement.scss";
import DisplayTrackRaces from "../Components/DisplayTrackRaces/displayTrackRaces";
import SelectedRaceComponent from "../Components/SelectedRace/selectedRaceComponent";
import { PoolDTO, ProviderOddsDTO } from "../../Models/PoolsDTO";
import { CLOG, showAPIToast } from "../../Helpers/ui-helper";
import {
  getLocalStorageObject,
  getPoolKeyStr,
  getRaceKeyStr,
  getRaceTripKeyStr,
  setLocalStorage,
} from "../../Helpers/valueHelper";
import { FixedOddsManagementService } from "../../services/fixedOddsManagementService";
import { __SelToRunner } from "../../Models/dynamicTypes";
import { FixedOddsContextProvider } from "../../Context/fixedOddsContext";
import { ScheduleApiService } from "../../services/scheduleApiService";
import { TotalsDTO } from "../../Models/investmentDTO";
import PoolsTab from "./PoolsTab/poolsTab";
import TripsTable from "./TripsTable/TripsTable";
import { JWTResponseDTO } from "../../Models/UserManagementDto";
import { ZoneId } from "@js-joda/core";
import LargeLoader from "../../UI-Components/Loader/loaders";
import { useHistory } from "react-router";
import ProviderOddsDialog from "./ProviderOddsDialog/providerOddsDialog";
import { RiskTableDTO } from "../../Models/RiskTableDTO";

const FixedOddsManagement = () => {
  const history = useHistory();

  const [riskTableData, setRiskTableData] = useState<RiskTableDTO>(
    {} as RiskTableDTO
  );

  const [waitingToReconnect, setWaitingToReconnect] = useState<Boolean>(true);
  const [isLoading, setIsLoading] = useState(false);
  const [dialogueShow, setDialogueShow] = useState(false);
  const [oddDataCols, setOddDataCols] = useState(ColsOdds);
  const [poolsList, setPoolsList] = useState<PoolDTO[]>([]);
  const [selProviderOdds, setSelProviderOdds] = useState<any>({});
  const [selectedRace, setselectedRace] = useState<RaceDetailsDTO>(
    {} as RaceDetailsDTO
  );
  const [selectedTrack, setSelectedTrack] = useState<any>({});
  const [selectedRaceTrips, setSelectedRaceTrips] = useState<__SelToRunner>({});
  const [selectedProgramNo, setSelectedProgramNo] = useState<string>("");

  const [selectedPool, setSelectedPool] = useState<PoolDTO>({} as PoolDTO);

  const [providersOddsCols, setProvidersOddsCols] = useState<TableColumnsDTO[]>(
    []
  );
  const [providersOddsList, setProvidersOddsList] = useState<any>([]);
  const [raceConnectorInfo, setRaceConnectorInfo] = useState<any>({});


  const [totalsData, setTotalsData] = useState({
    totalBets: 0,
    totalInvestment: 0,
    totalPosition: 0,
  } as TotalsDTO);


  const poolsObj = useRef<any>();
  const _pool = useRef<any>();

  const wsRacePools = useRef<any>(null);

  const raceRef = useRef<RaceDetailsDTO>(selectedRace);
  const poolRef = useRef<PoolDTO>(selectedPool);
  const tripsRef = useRef<__SelToRunner>(selectedRaceTrips);

  useEffect(() => {
    const fetchServerTimeZone = () => {
      ScheduleApiService.getServerTimeZone()
        .then((res) => {
          if (res.data) setLocalStorage(LOCAL_STORAGE.TIMEZONE, res.data);
          else
            setLocalStorage(
              LOCAL_STORAGE.TIMEZONE,
              ZoneId.systemDefault().toString()
            );
        })
        .catch((err) => {
          console.log(err);
          setLocalStorage(
            LOCAL_STORAGE.TIMEZONE,
            ZoneId.systemDefault().toString()
          );
        });
    };
    // Fetch and save server time zone in local storage
    fetchServerTimeZone();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (waitingToReconnect) {
      return;
    }

    if (!wsRacePools.current) {
      let jwtData: JWTResponseDTO = getLocalStorageObject(LOCAL_STORAGE.JWT);
      if (!jwtData) {
        return;
      }

      let socketUrl =
        SERVER.WS_URL_RACEPOOLS +
        "racePools?raceKey=" +
        raceRef.current.raceKeyString +
        "&token=" +
        jwtData.token;

      const client = new WebSocket(socketUrl);
      wsRacePools.current = client;
      wsRacePools.current.onopen = () => {
        CLOG(
          `___WSO____RACEPOOLS SOCKET OPEN FOR ${raceRef.current.raceKeyString}`,
          "green"
        );
        getRacePools(raceRef.current?.raceKeyString);
        setIsLoading(false);
      };

      wsRacePools.current.onmessage = (message: any) => {
        if (message && message.data) {
          let data = JSON.parse(message.data);

          if (data.messageType === "PROVIDERS_ODDS") {
            providerOddsUpdates(data);
          } else if (data.msg.poolKey) {
            normalUpdates(data);
          }
        }

        function normalUpdates(data: any) {
          let pkStr = getPoolKeyStr(data.msg.poolKey);
          // Checking if selected race and pool to only render when selected
          if (
            data.raceKey === raceRef.current?.raceKeyString &&
            poolRef.current?.poolDetails?.poolKeyString === pkStr
          ) {
            switch (data.messageType) {
              case "POOLLB":
                // updateLiabilityData(data.msg);
                updateRiskTableData(data.msg);
                break;
            }
          }
        }
      };
      wsRacePools.current.onclose = () => {
        if (wsRacePools.current) {
          // Connection failed
          setIsLoading(true);
          CLOG(`___WSC____RACEPOOLS SOCKET CLOSED BY SERVER`, "red");
        } else {
          // Cleanup initiated from app side, can return here, to not attempt a reconnect
          CLOG(`___WSC____RACEPOOLS SOCKET CLOSED BY APP COMPONENT`, "red");
          return;
        }
        if (waitingToReconnect) {
          return;
        }
        // Setting this will trigger a re-run of the effect,
        // cleaning up the current websocket, but not setting
        // up a new one right away
        setWaitingToReconnect(true);
        // This will trigger another re-run, and because it is false,
        // the socket will be set up again
        setTimeout(() => setWaitingToReconnect(false), 500);
      };
      return () => {
        CLOG(
          `___WSC____RACEPOOLS SOCKET CLEAN UP FOR ${raceRef.current.raceKeyString} `,
          "gray"
        );
        // Dereference, so it will set up next time
        wsRacePools.current = null;
        client.close();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waitingToReconnect]);

  // To open FO race in   Schedule management
  const openRaceInSchedule = () => {
    localStorage.setItem(
      LOCAL_STORAGE.SELECTEDRACEKEY,
      JSON.stringify(raceRef.current.raceKey)
    );
    history.push(ROUTES.SCHEDULE);
  };

  const RedirectToShedule = () => {
    history.push(ROUTES.SCHEDULE);
  };

  // Update liability data
  // const updateLiabilityData = (lbData: LiabilityDTO) => {
  // let sum = 0;
  // for (const selId in lbData.odds) {
  //   const odds = lbData.odds[selId];
  //   if (odds && odds !== 0) sum = sum + 1 / odds;
  // }
  // lbData.lbPercentage = (sum * 100).toFixed(2);
  // setLiabilityData(lbData);
  // };

  // Listening for providers odds updates
  const providerOddsUpdates = (data: any) => {
    let key = data.msg.poolKey;
    let raceKeyStr = getRaceKeyStr(key);
    let totePool = poolRef.current.poolDetails.poolKey.poolType.substring(3);
    if (
      raceRef.current.raceKeyString === raceKeyStr &&
      totePool === key.poolType
    ) {
      let tempObj: any = {};
      let columns: TableColumnsDTO[] = [{ field: "selId", header: "Sel No." }];
      let providersObj = data.msg?.providerOddsBySel ?? {};
      for (let provider in providersObj) {
        columns.push({ field: provider, header: provider });
        for (let sel in providersObj[provider]) {
          if (tempObj[sel]) {
            let obj = { ...tempObj[sel] };
            obj[provider] = providersObj[provider][sel];
            obj["selId"] = sel;
            tempObj[sel] = obj;
          } else {
            let obj = {} as any;
            obj[provider] = providersObj[provider][sel];
            obj["selId"] = sel;
            tempObj[sel] = obj;
          }
        }
      }

      if (tempObj[selectedProgramNo]) {
        let tempList = [];
        let objKeys = Object.keys(tempObj[selectedProgramNo]);
        for (let key of objKeys) {
          let tempPO = {} as ProviderOddsDTO;
          if (key !== "selId") {
            tempPO.provider = key;
            tempPO.odds = tempObj[selectedProgramNo][key];
            tempPO.selId = tempObj[selectedProgramNo]["selId"];
            tempList.push(tempPO);
          }
        }
        setSelProviderOdds(tempList);
      }
      setProvidersOddsList(Object.values(tempObj));
      setProvidersOddsCols(columns);
    }
  };

  // After click on race from race Schedular
  const onRaceClick = (race: RaceDetailsDTO) => {
    if (selectedRace?.raceKeyString === race.raceKeyString) return;

    if (!race?.raceKeyString) return;
    raceRef.current = race;

    setWaitingToReconnect(false);
    setIsLoading(true);
    if (wsRacePools.current) {
      wsRacePools.current.close();
    }
    setWaitingToReconnect(false);
    setIsLoading(true);

    setselectedRace(race);
    getRacePools(race);
    getRaceTrips(race);
  };

  // on click of pool tab of selected provider
  const onSelectPool = (pool: PoolDTO) => {
    !isLoading && setIsLoading(true);
    poolRef.current = pool;
    _pool.current = pool;
    setSelectedPool(pool);
    manageColumns(pool);
    FixedOddsManagementService.getLiabilities(pool.poolDetails.poolKeyString)
      .then((res) => {
        CLOG("=> API____GET LIABILITIES____", "purple");
        // let lbData = res.data as LiabilityDTO;
        let risk_table_data = res.data as RiskTableDTO;
        updateRiskTableData(risk_table_data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
      });
  };

  const updateRiskTableData = (risk_table_data: RiskTableDTO) => {
    let totals: TotalsDTO = {
      totalBets: 0,
      totalInvestment: 0,
      totalPosition: 0,
      totalPayout: 0,
    };
    for (const selId in risk_table_data.positions) {
      const position = risk_table_data.positions[selId] ?? 0;
      totals.totalPosition += position;
    }

    for (const selId in risk_table_data.betsCount) {
      const bets = risk_table_data.betsCount[selId] ?? 0;
      const inv = risk_table_data.investment[selId] ?? 0;
      totals.totalBets += bets;
      if (inv) totals.totalInvestment += inv;
    }

    totals.totalInvestment = Number(totals.totalInvestment.toFixed(2));
    totals.totalPosition = Number(totals.totalPosition.toFixed(2));

    setTotalsData(totals);
    setRiskTableData(risk_table_data);
  };

  // Get pools for selected race after onRaceClick
  const getRacePools = (race: RaceDetailsDTO) => {
    setIsLoading(true);
    if (race.raceKeyString) {
      FixedOddsManagementService.getRacePools(race.raceKeyString)
        .then((res) => {
          if (res && res.data) {
            CLOG("__API__GET_POOLS", "#45cd54");
            let poolsData = res.data as PoolDTO[];
            let pools = {} as any;
            for (let pool of poolsData) {
              if (pool.poolDetails.poolKey.poolType.startsWith("FO_")) {
                pool.poolDetails.poolKeyString = getPoolKeyStr(
                  pool.poolDetails.poolKey
                );
                pools[pool.poolDetails.poolKeyString] = pool;
              }
            }
            setPoolsList(pools);
            poolsObj.current = pools;
            setIsLoading(false);
            let tList: any = Object.values(pools).sort((a: any, b: any) =>
              a.poolDetails.poolKey.poolType > b.poolDetails.poolKey.poolType
                ? -1
                : 1
            );
            if (tList.length) {
              onSelectPool(tList[0]);
            }
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.error(err);
          showAPIToast(err, "while fetching race pools data", "racePools");
        });
    }
  };

  // Managing columns according to layoff
  const manageColumns = (pool: PoolDTO) => {
    let cols = [...ColsOdds];
    let layOff = pool.poolDetails.layOff;

    if (!layOff) {
      let i = cols.findIndex((x) => x.field === "layOffInvestment");
      cols.splice(i, 1);
    }
    setOddDataCols(cols);
  };

  // Get race trips if FO pools available
  const getRaceTrips = (race: RaceDetailsDTO) => {
    ScheduleApiService.getRaceTrips(race.raceKeyString)
      .then((res) => {
        let tripObj: __SelToRunner = {};
        if (res.data) {
          let trips = res.data as RaceTripDTO[];
          CLOG("=> API____RACE TRIPS FETCHED____", "purple");

          for (let trip of trips) {
            trip.raceTripKeyStr = getRaceTripKeyStr(trip.raceTripKey);
            tripObj[trip.raceTripKeyStr] = trip;
          }
        }
        tripsRef.current = tripObj;
        setSelectedRaceTrips(tripObj);
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
        setSelectedRaceTrips({});
        tripsRef.current = {};
        showAPIToast(err, "while fetching runner detail", "tripsFetch");
      });
  };

  // Socket Update in from Race Trips
  const updateFixedOddsRaceTrips = (trip: RaceTripDTO) => {
    if (tripsRef.current) {
      trip.raceTripKeyStr = getRaceTripKeyStr(trip.raceTripKey);
      let _selectedTrips: __SelToRunner = { ...tripsRef.current };
      _selectedTrips[trip.raceTripKeyStr] = trip;
      setSelectedRaceTrips(_selectedTrips);
      tripsRef.current = _selectedTrips;
    }
  };

  // Floating Open race Button
  const FloatButton = () => {
    return (
      <>
        <Tooltip
          target=".temp"
          content="Open This Race in Schedule Management"
          position="left"
        />
        {selectedRace && (
          <button className="open-btn-race temp" onClick={openRaceInSchedule}>
            <i className="fa fa-external-link"></i>
          </button>
        )}
      </>
    );
  };

  const updatePoolDataFromSocket = (pool: PoolDTO) => {
    let pools = { ...poolsObj.current };
    if (pool.poolDetails.poolKey.poolType.startsWith("FO_")) {
      pool.poolDetails.poolKeyString = getPoolKeyStr(pool.poolDetails.poolKey);
      pools[pool.poolDetails.poolKeyString] = pool;
      poolsObj.current = pools;
      if (
        _pool.current.poolDetails.poolKeyString ===
        pool.poolDetails.poolKeyString
      ) {
        onSelectPool(pool);
      }
      setPoolsList(pools);
    }
  };

  return (
    <FixedOddsContextProvider
      value={{
        selectedRace,
        selectedRaceTrips,
        selectedPool,
        setSelectedPool,
        onSelectPool,
        riskTableData,
        totalsData,
        providersOddsCols,
        setProvidersOddsCols,
        providersOddsList,
        setProvidersOddsList,
        selectedProgramNo,
        setSelectedProgramNo,
        selProviderOdds,
        setSelProviderOdds,
        setRiskTableData,

      }}
    >
      <div>
        <Header pageTitle="Fixed Odds Management" />
        {dialogueShow && (
          <ProviderOddsDialog
            dialogueShow={dialogueShow}
            setDialogShow={(e) => setDialogueShow(e)}
          />
        )}
        {selectedTrack?.races && <FloatButton />}
        <LargeLoader isLoading={isLoading} />

        <div className="content heightfull">
          <div className="content-left-panel">
            <RaceSchedular
              updateRaceDetails={setselectedRace}
              updatePoolData={updatePoolDataFromSocket}
              updateRaceTrips={updateFixedOddsRaceTrips}
              onRaceClick={() => {}}
              setSelectedTrack={setSelectedTrack}
              selectedTrack={selectedTrack}
              selectedRace={selectedRace}
              raceConnectorInfo={raceConnectorInfo}
              setRaceConnectorInfo={setRaceConnectorInfo}
            />
          </div>
          {Object.keys(selectedTrack).length > 0 ? (
            <div
              className="content-middle-panel w-100"
              style={{ paddingBottom: "6rem" }}
            >
              <div className="selected-event-container p-1">
                <DisplayTrackRaces
                  onRaceClick={onRaceClick}
                  raceConnectorInfo={raceConnectorInfo}
                  selectedRaces={selectedTrack?.races ?? []}
                  selectedRace={selectedRace}
                />
              </div>
              <SelectedRaceComponent race={selectedRace} />
              <div className="d-flex">
                <div
                  className="providers-btn"
                  onClick={() => setDialogueShow(true)}
                >
                  <span>Providers</span>{" "}
                  <span className="h3 font-weight-bolder m-0">Odds</span>
                </div>
                <PoolsTab poolsList={poolsList} />
              </div>
              <TripsTable columns={oddDataCols} />
            </div>
          ) : (
            <div className="empty-status">
              <h2 className="mb-4">
                No Upcoming Tracks! & Fo Generated Races!
              </h2>
              <button onClick={RedirectToShedule} className="pz-btn primary">
                Back To Schedule Management
              </button>
            </div>
          )}
        </div>
      </div>
    </FixedOddsContextProvider>
  );
};

export default FixedOddsManagement;
