import { confirmPopup } from "primereact/confirmpopup";
import { Dropdown } from "primereact/dropdown";
import { SelectButton } from "primereact/selectbutton";
import { useContext, useEffect, useState } from "react";
import { ScheduleContext } from "../../../../Context/scheduleContext";
import { FixOddsRuleList, LOCAL_STORAGE } from "../../../../Helpers/constants";
import {
  ProvidersList,
  CurrencyList,
  ManagedByList,
  PoolStatuses,
} from "../../../../Helpers/StaticData/pool-data-static";
import {
  CLOG,
  showAPIToast,
  showToast,
  TOAST,
} from "../../../../Helpers/ui-helper";
import { validateMinValue } from "../../../../Helpers/Validators/input-validations";
import {
  getLocalStorageObject,
  getNumberValue,
} from "../../../../Helpers/valueHelper";
import { PayOffDTO, PoolConfigDTO, PoolDTO } from "../../../../Models/PoolsDTO";
import { UserDTO } from "../../../../Models/UserManagementDto";
import { ScheduleApiService } from "../../../../services/scheduleApiService";
import PzCheckBox from "../../../../UI-Components/Inputs/checkBoxInput";
import PZInputField from "../../../../UI-Components/Inputs/inputField";
import PzDropDown from "../../../../UI-Components/Inputs/pzDropDown";
import PayOffTable from "../../../../UI-Components/Table/payOffTable";
import { toast } from "react-toastify";
import { Chips } from "primereact/chips";
import {
  PrimeItem,
  ProviderOptions,
} from "../../../../Helpers/StaticData/race-data";
import { FixedOddsManagementService } from "../../../../services/fixedOddsManagementService";

export interface IEditPoolFormProps {
  selectedPool: PoolDTO;
  onFilterConnectorList: any;
  isPoolsOpen: any;
  setIsPoolsOpen: any;
}

export default function EditPoolForm(props: IEditPoolFormProps) {
  const InitPoolKey = {
    localDate: 0,
    poolType: "",
    provider: "DEFAULT",
    pzoneCode: "",
    raceNo: 0,
  };

  const InitPoolConfig: PoolConfigDTO = {
    // scratchedDedPer: 0,
    // scratchedProfileId: null,
    // deductionUsing: null,
    // oddsManagedBy: null,
    // oddsCrunchPerByProvider: null,
    // oddsProfileId: null,
    // poolKey: InitPoolKey,
    // userId: null,
    // accountId: null,

    // openTime: "",
    lastUpdated: null,
    minBookPer: 0,
    // isSystemOpen: false,
    // accountId: "",
    // scratchedDedPer: 0,
    // deductionUsing: null,
    scratchedProfileId: "",
    fixOddsValue: 0,
    fixOddsRule: 0,
    userId: "",
    deductionType: "",
    deductionValue: "",
    oddsManagedBy: null,
    // timeZone: "",
    mbaType: "",
    mbaVal: 0,
    layoffPer: 0,
  };
  const InitPool: PoolDTO = {
    poolConfig: InitPoolConfig,
    poolDetails: {
      layOff: false,
      cancelled: false,
      currency: "",
      incBetAmt: 0,
      lastUpdated: 0,
      minBetAmt: 0,
      otherLegs: "",
      payOffList: [],
      payoff: {},
      poolKey: InitPoolKey,
      linkedWith: "",
      poolKeyString: "",
      providerId: "",
      resulted: true,
      skipFields: null,
      poolStatus: "NON_DISPLAY",
    },
  };

  const {
    usersList,
    scrProfileList,
    selectedRace,
    togglePoolForm,
    setPoolToEdit,
    startLoader,
    stopLoader,
    totalRaces,
  } = useContext(ScheduleContext);

  const [pool, setPool] = useState(props.selectedPool);
  const [linkedPoolOptions, setlinkedPoolOptions] = useState([]);
  const [isFOPool, setisFOPool] = useState(false);
  const [payOff, setPayOff] = useState<PayOffDTO>({ selId: "", payOff: 0 });
  const [selectedLinkedWith, setSelectedLinkedWith] = useState(null);
  const [otherLegsValues, setOtherLegsValues] = useState<string[]>([]);

  const _user = getLocalStorageObject(LOCAL_STORAGE.USER) as UserDTO;

  useEffect(() => {
    if (props.selectedPool) {
      let _pool = JSON.parse(JSON.stringify(props.selectedPool));
      if (
        _pool.poolDetails.poolKey.poolType === "DOUBLE" ||
        _pool.poolDetails.poolKey.poolType === "PICK_3" ||
        _pool.poolDetails.poolKey.poolType === "PICK_4"
      ) {
        setOtherLegsValues(_pool.poolDetails.otherLegs.split(","));
      } else {
        setOtherLegsValues([]);
      }

      if (_pool.poolDetails.poolKey.poolType.startsWith("FO_")) {
        setisFOPool(true);
        if (!_pool.poolConfig) _pool.poolConfig = InitPoolConfig;
      } else {
        setisFOPool(false);
      }
      setPool(_pool);
    } else {
      setPool(InitPool);
    }
    onPoolTypeChange(JSON.parse(JSON.stringify(props.selectedPool)));
    return () => {
      setPool(InitPool);
      setlinkedPoolOptions([]);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedPool]);

  // useEffect(() => {
  //   if (pool?.poolConfig?.fixOddsRule === "FIXED") getProviders();
  // }, [pool.poolConfig?.fixOddsRule]);

  const onBack = () => {
    togglePoolForm(false);
    setPoolToEdit(null);
  };

  const onPoolTypeChange = (_pool: any) => {
    startLoader();
    let poolCategory = _pool.poolDetails.poolKey.poolType.startsWith("FO_")
      ? "FO"
      : "TOTE";

    if (poolCategory === "FO") {
      setisFOPool(true);
    } else {
      _pool.poolDetails.layOff = false;
      setisFOPool(false);
    }
    setPool({ ..._pool });
    let country = selectedRace.raceKey.pzoneCode.split("-")[0];
    _getLinkedWithPools(country, poolCategory, _pool);

    console.log(_pool);
    // setSelectedLinkedWith(null);
  };

  const _getLinkedWithPools = (
    country: string,
    poolCategory: string,
    _pool: any
  ) => {
    ScheduleApiService.getProviderList(country, poolCategory)
      .then((res) => {
        stopLoader();
        if (res.data) {
          setlinkedPoolOptions(res.data);
          for (const data of res.data) {
            if (_pool.poolDetails.linkedWith === data.provider) {
              _pool.poolDetails.linkedWith = data.provider;
              _pool.poolDetails.currency = data.currency;
              // _pool.poolDetails.currency = _pool.poolDetails.currency
              setSelectedLinkedWith(data);
              setPool({ ..._pool });
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
        stopLoader();
        showAPIToast(err, "while fetchin linked with pools!", "linked_with");
      });
  };

  const onLinkedWithPoolChange = (e: any) => {
    let _pool = { ...pool };
    _pool.poolDetails.linkedWith = e.target.value.provider;
    _pool.poolDetails.currency = e.target.value.currency;
    setSelectedLinkedWith(e.target.value);
    setPool(_pool);
  };

  const onPoolKeyDropDownChange = (e: any) => {
    let _pool = { ...pool } as any;
    _pool.poolDetails.poolKey[e.target.name] = e.target.value;
    setPool(_pool);
  };

  const onDropdownChange = (e: any) => {
    let _pool = { ...pool } as any;
    _pool.poolDetails[e.target.name] = e.target.value;
    setPool(_pool);
  };

  const onConfigDropChange = (e: any) => {
    let _pool = { ...pool } as any;
    if (!_pool.poolConfig) {
      _pool.poolConfig = InitPoolConfig;
    }
    if (e.target.name === "fixOddsRule") {
      // if (_pool.poolConfig.fixOddsRule === "FIXED") getProviders();
      _pool.poolConfig["fixOddsValue"] = 0;
    }
    if (e.target.name === "providerId") {
      _pool.poolDetails[e.target.name] = e.target.value;
    } else {
      _pool.poolConfig[e.target.name] = e.target.value;
    }
    // _pool.poolConfig[e.target.name] = e.target.value;
    setPool(_pool);
  };

  const onPoolDetailChange = (e: any) => {
    let _pool = { ...pool } as any;
    let name = e.target.name;
    if (name === "minBetAmt" || name === "incBetAmt") {
      let valid = validateMinValue(Number(e.target.value), 0);
      if (!valid) return console.error("not valid");
    }
    _pool.poolDetails[e.target.name] = e.target.value;
    setPool(_pool);
  };

  const onOtherLegChange = (e: any) => {
    let _pool = { ...pool } as any;
    _pool.poolDetails.otherLegs = e.value.toString();
    setPool(_pool);
    setOtherLegsValues(e.value);
  };

  const onPoolBooleansChange = (e: any) => {
    let _pool = { ...pool } as any;
    _pool.poolDetails[e.name] = e.value;
    setPool(_pool);
  };

  const onPayOffChange = (e: any) => {
    let pay = { ...payOff } as any;
    pay[e.target.name] = e.target.value;
    setPayOff(pay);
  };

  const onPoolStatusChange = (e: any) => {
    let _pool = { ...pool };
    if (e.value) {
      _pool.poolDetails.poolStatus = e.value;
      setPool(_pool);
    }
  };

  const onEditActionClick = (rowData: PayOffDTO, index: number) => {
    let _pool = { ...pool };
    _pool.poolDetails.payOffList.splice(index, 1);
    setPool(_pool);
    setPayOff(rowData);
  };

  const onDeleteActionClick = (index: number) => {
    let _pool = { ...pool };
    _pool.poolDetails.payOffList.splice(index, 1);
    setPool(_pool);
  };

  const handleKeyUp = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      onAddPayOff();
    }
  };

  const checkValidationOnPool = () => {
    if (
      pool?.poolDetails?.poolKey?.poolType === "DOUBLE" ||
      pool?.poolDetails?.poolKey?.poolType === "PICK_3" ||
      pool?.poolDetails?.poolKey?.poolType === "PICK_4"
    ) {
      if (pool.poolDetails?.otherLegs.length === 0) {
        showToast("Other Lags Is Required", TOAST.ERROR);
        return false;
      } else if (selectedRace.raceKey.raceNo === totalRaces) {
        showToast(
          `you cannot create pool on this race ${totalRaces}`,
          TOAST.ERROR
        );
        return false;
      } else if (pool?.poolDetails?.poolKey?.poolType === "DOUBLE") {
        if (
          pool.poolDetails?.otherLegs <= selectedRace.raceKey.raceNo ||
          pool.poolDetails?.otherLegs > totalRaces
        ) {
          showToast(
            `Race should be greater than ${selectedRace.raceKey.raceNo} or less  or equal ${totalRaces}`,
            TOAST.ERROR
          );
          return false;
        }
      } else if (pool?.poolDetails?.poolKey?.poolType === "PICK_3") {
        let values = pool.poolDetails?.otherLegs.split(",");
        return checkPickValidation(values);
      } else if (pool?.poolDetails?.poolKey?.poolType === "PICK_4") {
        let values = pool.poolDetails?.otherLegs.split(",");
        return checkPickValidation(values);
      }
    }
  };

  const validateEditPool = () => {
    if (checkValidationOnPool() === false) return;
    if (!selectedLinkedWith && pool.poolConfig?.oddsManagedBy === "AUTO") {
      showToast("Please select linked with pool.", TOAST.ERROR);
      return false;
    }

    if (pool.poolConfig?.layoffPer.toString().length === 0) {
      return showToast(
        "Layoff Percentage should be between 1 to 100",
        TOAST.ERROR
      );
    }

    if (!pool.poolDetails.currency) {
      showToast("Please select currency!", TOAST.ERROR, "currency");
      return false;
    }
    if (!pool.poolDetails.minBetAmt || !pool.poolDetails.incBetAmt) {
      showToast("Please select Bet Amount!", TOAST.ERROR, "BetAmount");
      return false;
    }
    if (!pool.poolDetails.poolKey.provider) {
      showToast("Please select provider!", TOAST.ERROR, "provider");
      return false;
    }
    if (
      !pool.poolDetails.linkedWith &&
      pool.poolConfig?.oddsManagedBy === "AUTO"
    ) {
      showToast("Please select linked with pool.", TOAST.ERROR);
      return false;
    }
    if (isFOPool && Number(pool.poolConfig?.minBookPer) < 100) {
      showToast("minBookPer value should be greaterthan 100 !", TOAST.ERROR);
      return false;
    }
    return true;
  };

  const checkPickValidation = (values: any) => {
    for (const val of values) {
      if (val <= selectedRace.raceKey.raceNo || val > totalRaces) {
        showToast(
          `Race should be greater than ${selectedRace.raceKey.raceNo} or less  or equal ${totalRaces}`,
          TOAST.ERROR
        );
        return false;
      }
    }
  };

  const confirmUpdate = (event: any) => {
    if (validateEditPool()) {
      confirmPopup({
        target: event.currentTarget,
        message: "Are you sure you want to proceed to update this pool?",
        icon: "pi pi-exclamation-triangle",
        accept: () => updatePoolData(),
        reject: () => {},
      });
    }
  };

  const modifyPoolData = () => {
    let _pool = { ...pool } as any;
    _pool.poolDetails.payoff = {};

    if (_pool?.poolDetails?.poolStatus === "RESULTED") {
      if (!_pool?.poolDetails?.payOffList?.length) {
        showToast("Please enter at least one pay off.", TOAST.ERROR);
        return false;
      }

      for (let pay of _pool.poolDetails.payOffList) {
        _pool.poolDetails.payoff[pay.selId] = Number(pay.payOff);
      }
    }
    _pool.poolDetails.poolKey.localDate = selectedRace.raceKey.localDate;
    _pool.poolDetails.poolKey.pzoneCode = selectedRace.raceKey.pzoneCode;
    _pool.poolDetails.poolKey.raceNo = selectedRace.raceKey.raceNo;
    _pool.poolConfig.poolKey = _pool.poolDetails?.poolKey;

    return _pool;
  };

  const updatePoolData = () => {
    let _pool = modifyPoolData();
    if (!_pool) return;
    let poolKeyStr = _pool.poolDetails.poolKeyString;
    let payOffList = _pool.poolDetails.payOffList;
    delete _pool.poolDetails.payOffList;
    delete _pool.poolDetails.poolKeyString;
    _pool.poolDetails.resulted = true;

    if (_pool.poolConfig.oddsManagedBy === "AUTO") {
      _pool.poolConfig.userId = "";
    }

    const endPoint = isFOPool ? "update-book-config" : "update-pool-config";

    ScheduleApiService.updatePoolConfig(_pool, endPoint)
      .then((res) => {
        console.log(res);
        if (res.data) {
          _pool.poolDetails.poolKeyString = poolKeyStr;
          _pool.poolDetails.payOffList = payOffList;
          setPool(_pool);
          togglePoolForm(false);
          showToast("Pool Updated Successfully!", TOAST.SUCCESS);
          props.setIsPoolsOpen(false);
          props.isPoolsOpen(false);
        } else toast.error("response Not Received");
      })
      .catch((err) => {
        console.error(err.response);
        let options = {
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose: 3500,
          style: { fontSize: 16, width: "37rem" },
        };
        toast.error(err?.response?.data?.message, options);
      });
  };

  const onAddPayOff = () => {
    let _pool = { ...pool };
    if (payOff.selId && payOff.payOff) {
      if (!_pool.poolDetails.payOffList) _pool.poolDetails.payOffList = [];
      _pool.poolDetails.payOffList.push(payOff);
      setPool(_pool);
      setPayOff({ selId: "", payOff: 0 });
    }
  };
  const handleLayoutPoolChange = (e: any) => {
    let _pool = { ...pool } as any;
    const value = e.target.value;
    if ((Number(value) && value <= 0) || value > 100) {
      return showToast(
        "Layoff Percentage should be between 1 to 100",
        TOAST.ERROR
      );
    }
    _pool.poolConfig[e.target.name] = value;
    setPool(_pool);
  };

  const FOInputTemplate = () => {
    if (isFOPool)
      return (
        <>
          <PzDropDown
            label="Odds Manage By"
            value={pool.poolConfig?.oddsManagedBy}
            options={ManagedByList}
            classNameWrp="py-4"
            name="oddsManagedBy"
            onChange={onConfigDropChange}
            disabled={_user.uid !== pool.poolConfig?.userId}
          />
          <PzDropDown
            label="Scratch Profile ID"
            value={pool.poolConfig?.scratchedProfileId}
            options={scrProfileList}
            classNameWrp="py-4"
            name="scratchedProfileId"
            onChange={onConfigDropChange}
          />
          <PZInputField
            className="py-4"
            onChange={onConfigDropChange}
            type="number"
            name="minBookPer"
            value={getNumberValue(pool?.poolConfig?.minBookPer ?? 0)}
            label="minBookPer"
          />
          <PzDropDown
            label="FixOdds Rule"
            value={pool?.poolConfig?.fixOddsRule}
            options={FixOddsRuleList}
            classNameWrp="py-4"
            name="fixOddsRule"
            onChange={onConfigDropChange}
          />
          {pool.poolConfig?.fixOddsRule === "LIB" && (
            <PZInputField
              className="py-4"
              onChange={onConfigDropChange}
              type="number"
              name="fixOddsValue"
              value={getNumberValue(pool?.poolConfig?.fixOddsValue ?? 0)}
              label={"Liability Value (%)"}
            />
          )}
          {pool?.poolConfig?.fixOddsRule === "FIXED" && (
            <PzDropDown
              label="Providers"
              value={pool.poolDetails.providerId}
              options={ProviderOptions ?? []}
              classNameWrp="py-4"
              name="providerId"
              onChange={onConfigDropChange}
            />
          )}
        </>
      );
  };

  const manualManageTemplate = () => {
    if (pool.poolConfig?.oddsManagedBy === "MANUAL")
      return (
        <>
          <PzDropDown
            label="Managing User"
            placeHolder="Select user to manage"
            value={pool.poolConfig?.userId ?? ""}
            options={usersList}
            classNameWrp="py-4"
            name="userId"
            onChange={onConfigDropChange}
            disabled
          />
        </>
      );
  };

  const connectorTemplate = () => {
    if (!pool.poolDetails?.poolKey?.poolType) return null;
    if (isFOPool && !pool.poolDetails.layOff) return null;
    // let connectorsOptions = props.onFilterConnectorList(pool);
    return (
      <>
        {/* <PzDropDown
          label="Connector"
          value={pool.poolConfig?.accountId ?? ""}
          options={connectorsOptions ?? []}
          classNameWrp="py-4"
          emptyMsg="No connector account info available!"
          name="accountId"
          onChange={onConfigDropChange}
        /> */}
        {pool.poolDetails?.layOff && (
          <PZInputField
            className="py-4"
            onChange={handleLayoutPoolChange}
            type="text"
            name="layoffPer"
            value={getNumberValue(pool.poolConfig?.layoffPer ?? 0)}
            label="Layoff Per"
          />
        )}
      </>
    );
  };

  const resultedStatusTemplate = () => {
    if (pool?.poolDetails?.poolStatus === "RESULTED")
      return (
        <div>
          <div className="py-4">
            <label htmlFor="">Pay Off Details:</label>
            <div className="d-flex align-items-center my-3">
              <PZInputField
                type="text"
                value={payOff.selId}
                label="selection"
                name="selId"
                onChange={onPayOffChange}
                inputClass="p-2"
                onKeyUp={handleKeyUp}
              />
              <PZInputField
                type="number"
                value={getNumberValue(payOff.payOff)}
                label="Pay Off"
                inputClass="p-2"
                name="payOff"
                onKeyUp={handleKeyUp}
                onChange={onPayOffChange}
              />
              <button onClick={onAddPayOff} className="pz-btn primary">
                Add
              </button>
            </div>
          </div>
          <div>
            <PayOffTable
              isEditAction
              onEditClick={onEditActionClick}
              onDeleteClick={onDeleteActionClick}
              data={pool.poolDetails.payOffList}
              columns={[
                { field: "selId", header: "Selection" },
                { field: "payOff", header: "Pay Off" },
              ]}
            />
          </div>
        </div>
      );
  };

  return (
    <div className="pl-form-container">
      <div className="form-title">
        <span></span>
        <span>Edit Pool</span>
        <button className="pz-btn icon-btn hover" onClick={onBack}>
          <i className="fa fa-arrow-right"></i>
        </button>
      </div>
      <div className="form-body">
        <div className="row m-0">
          <div className="col-6 b-right m-0">
            <div className="col-title">Enter Pool Details</div>
            <div className="input-grid-2">
              {/* <PzDropDown
                label="Pool Type"
                value={pool.poolDetails?.poolKey.poolType ?? ""}
                options={FOPoolsList}
                className="py-4"
                name="poolType"
                autoFocus
                disabled
                // onChange={() => onPoolTypeChange(pool)}
              /> */}
              <PZInputField
                className="py-4"
                onChange={() => onPoolTypeChange(pool)}
                name="poolType"
                value={pool.poolDetails?.poolKey.poolType ?? ""}
                label="Pool Type"
                disabled
                type="string"
              />
              <div
                className="input-container"
                style={{
                  width: "auto",
                  display: "inline",
                  alignSelf: "center",
                }}
              >
                <div className="input">
                  <label className="input-label">Linked With</label>
                  <Dropdown
                    options={linkedPoolOptions}
                    onChange={onLinkedWithPoolChange}
                    className="prime-drop"
                    optionLabel="provider"
                    value={
                      selectedLinkedWith ??
                      props.selectedPool.poolDetails.linkedWith
                    }
                  />
                </div>
              </div>

              <PzDropDown
                label="Provider"
                value={pool?.poolDetails?.poolKey?.provider ?? ""}
                options={ProvidersList}
                classNameWrp="py-4"
                name="provider"
                onChange={onPoolKeyDropDownChange}
                disabled
              />

              <PzDropDown
                label="Currency"
                value={pool?.poolDetails?.currency ?? ""}
                options={CurrencyList}
                classNameWrp="py-4"
                name="currency"
                onChange={onDropdownChange}
                disabled
              />
              <PZInputField
                className="py-4"
                onChange={onPoolDetailChange}
                type="number"
                name="minBetAmt"
                value={getNumberValue(pool?.poolDetails?.minBetAmt ?? 0)}
                label="Min Bet Amount"
              />
              <PZInputField
                className="py-4"
                onChange={onPoolDetailChange}
                type="number"
                name="incBetAmt"
                value={getNumberValue(pool?.poolDetails?.incBetAmt ?? 0)}
                label="Inc. Bet Amount"
              />
              {/* <PZInputField
                className="py-4"
                onChange={onPoolDetailChange}
                type="text"
                name="otherLegs"
                value={pool.poolDetails?.otherLegs ?? ""}
                label="Other Legs"
              /> */}

              {(pool?.poolDetails?.poolKey?.poolType === "DOUBLE" ||
                pool?.poolDetails?.poolKey?.poolType === "PICK_3" ||
                pool?.poolDetails?.poolKey?.poolType === "PICK_4") && (
                <div>
                  <label className="otherleglabel">Other Legs</label>
                  <Chips
                    className="input-container test"
                    value={otherLegsValues}
                    onChange={onOtherLegChange}
                    separator=","
                    max={
                      pool?.poolDetails?.poolKey.poolType === "DOUBLE"
                        ? 1
                        : pool?.poolDetails?.poolKey.poolType === "PICK_3"
                        ? 2
                        : 3
                    }
                    allowDuplicate={false}
                  ></Chips>
                </div>
              )}
            </div>
            <div className="d-flex pl-3">
              <PzCheckBox
                value={pool.poolDetails?.cancelled}
                onChange={onPoolBooleansChange}
                label="Canceled ?"
                name="cancelled"
              />
              {isFOPool && (
                <PzCheckBox
                  value={pool.poolDetails?.layOff}
                  onChange={onPoolBooleansChange}
                  label="Layoff ?"
                  name="layOff"
                />
              )}
            </div>
            <div className="py-4">
              <label>Pool Status : </label>
              <SelectButton
                optionLabel="label"
                optionValue="value"
                value={pool?.poolDetails?.poolStatus ?? []}
                options={PoolStatuses}
                onChange={onPoolStatusChange}
              ></SelectButton>
              {resultedStatusTemplate()}
            </div>
          </div>
          <div className="col-6 m-0">
            <div className="input-grid-2 mt-5 p-2">
              {connectorTemplate()}
              {FOInputTemplate()}
              {manualManageTemplate()}
            </div>
          </div>
        </div>
      </div>
      <div className="form-footer">
        <button
          onClick={confirmUpdate}
          className="pz-btn rounded primary my-4 mr-5"
        >
          <i className="fa fa-check"></i> &nbsp; Update
        </button>
      </div>
    </div>
  );
}
