import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "primereact/resources/themes/bootstrap4-light-purple/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "./assets/fonts/CodaCaption-ExtraBold.ttf";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "react-toastify/dist/ReactToastify.css";
import "font-awesome/css/font-awesome.min.css";
import { BrowserRouter } from "react-router-dom";
import SCRProvider from "./Context/searchCustomerReport";
ReactDOM.render(
  // <React.StrictMode>
    <BrowserRouter>
    <SCRProvider>
       <App />
    </SCRProvider>
    </BrowserRouter>,
  // </React.StrictMode>,
  document.getElementById("root")
);
reportWebVitals();
