import React, { useState, useEffect, useContext } from "react";
import "./ViewSearchCustomerReport.scss";
import PzDropDown from "../../../../UI-Components/Inputs/pzDropDown";
import { ScheduleApiService } from "../../../../services/scheduleApiService";
import { UserApiService } from "../../../../services/userApiService";
import { showToast, TOAST } from "../../../../Helpers/ui-helper";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import {
  SearchCustomerReports,
  ViewSearchCustomerProps,
} from "../../../../Models/searchCustomerReportDTO";
import { searchCustomerReportColumn } from "../../../../Helpers/columns";
import {
  eventTypeFullNameList,
  poolList,
} from "../../../../Helpers/StaticData/pool-data-static";
import SidebarNavigation from "../SidebarNavigation/SidebarNavigation";
import Header from "../../../../UI-Components/Header/header";
import AddFunction from "../AddFunction/AddFunction";
import LargeLoader from "../../../../UI-Components/Loader/loaders";
import { SCRContext } from "../../../../Context/searchCustomerReport";
import { Province } from "../../../../Helpers/StaticData/province-data";

const ViewSearchCustomerReport = (props: ViewSearchCustomerProps) => {
  // variables
  const { openCustomerPanal } = props;
  const customerType = "customer_report";
  const [filterValueObj, setFilterValueObj] = useState({} as any);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isEditFunction, setIsEditFunction] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [filterValue, setFilterValue] = useState({} as any);

  const contextSCR = useContext(SCRContext);
  const [isDoubleHashFuncSelected, setIsDoubleHashFuncSelected] =
    useState(false);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
  });

  // Send props
  const addFunctionProps = {
    isSidebarOpen,
    setIsSidebarOpen,
    filterValueObj,
    setFilterValueObj,
    isEditFunction,
    setIsEditFunction,
    filterValue,
    isDoubleHashFuncSelected,
    setIsDoubleHashFuncSelected,
  };

  // callbacks
  useEffect(() => {
    getProvince();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // functions
  const getProvince = () => {
    setIsLoading(true);
    ScheduleApiService.getProvinces()
      .then((res) => {
        if (res.data) {
          let country_list = [] as any;
          for (let key of res.data) {
            let countryObj: any = {};
            countryObj.label = Province[key.province].replace(/_/, " ");
            countryObj.value = key.province;
            country_list.push(countryObj);
          }
          let sort_Country_List = Object.values(country_list).sort(
            (a: any, b: any) => {
              return a.value > b.value ? 1 : -1;
            }
          );
          contextSCR?.setCountryList(sort_Country_List);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        showToast("province Data Not Found!", TOAST.ERROR);
      });
  };

  const viewTemplate = (rowData: any) => {
    return (
      <button className="pool-btn" onClick={() => openCustomerPanel(rowData)}>
        <i className="fa fa-eye" style={{ color: "#41f541" }}></i> &nbsp;&nbsp;
        View
      </button>
    );
  };

  const openCustomerPanel = (rowData: any) => {
    openCustomerPanal(rowData);
  };

  const onChangeRuleKey = (e: any) => {
    let _ruleId = { ...contextSCR.ruleId } as any;
    _ruleId[e.target.name] = e.target.value;
    contextSCR.setRuleId(_ruleId);
  };
  const onPage = (event: any) => {
    let lazyParam = {
      first: event.first,
      rows: event.rows,
      page: event.page,
    };
    handleSearch(lazyParam);
    setLazyParams(lazyParam);
  };

  const checkCustomerReportValidation = () => {
    let ruleKey = { ...contextSCR.ruleId };
    if (!ruleKey.country || !ruleKey.eventType || !ruleKey.poolType) {
      setIsLoading(false);
      showToast("Plase Select All Field", TOAST.ERROR);
      return false;
    }
    return true;
  };
  const searchCustomerReportReq = (lazyParams?: any) => {
    let reqDataObj = {} as SearchCustomerReports;
    reqDataObj.eventType = contextSCR.ruleId.eventType;
    reqDataObj.poolType = contextSCR.ruleId.poolType;
    reqDataObj.pageNo = lazyParams.page;
    reqDataObj.size = lazyParams.rows;
    reqDataObj.filters = filterValueObj;
    reqDataObj.province = contextSCR.ruleId.country;
    return reqDataObj;
  };

  const handleSearch = (lazyParams: any) => {
    setIsLoading(true);
    if (checkCustomerReportValidation()) {
      let requestBody = searchCustomerReportReq(
        lazyParams
      ) as SearchCustomerReports;
      UserApiService.findCustomerReport(requestBody)
        .then((res) => {
          if (res) {
            contextSCR.setSearchCustomerReportList(res.data.data);
            setTotalRecords(res.data.totalRecords);
          }
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err?.response);

          setIsLoading(false);
          showToast(err?.response?.data.error, TOAST.ERROR);
        });
    }
  };

  const onEditFilter = (key: string, value: any) => {
    setIsDoubleHashFuncSelected(false);
    let _filterObj: any = {};
    let tempList = value.split(",");
    let filterValueList = [];
    for (const data of tempList) {
      let funcObj: any = {};
      if (data === "##AND" || data === "##OR") {
        setIsDoubleHashFuncSelected(true);
        funcObj["funcName"] = data.split("(")[0];
        funcObj["funcValue"] = "";
      } else {
        funcObj["funcName"] = data.split("(")[0];
        funcObj["funcValue"] = data.substring(
          data.indexOf("(") + 1,
          data.indexOf(")")
        );
      }
      filterValueList.push(funcObj);
    }
    _filterObj.reportField = key;
    _filterObj.filter = filterValueList;
    setIsEditFunction(true);
    setIsSidebarOpen(true);
    setFilterValue(_filterObj);
  };
  const onHandleDeleteFilter = (res: string) => {
    let filterObject = { ...filterValueObj };
    delete filterObject[res];
    setFilterValueObj(filterObject);
  };

  return (
    <div className="search_customer_report">
      <Header pageTitle="Customer Search" />
      <LargeLoader isLoading={isLoading} />
      <div className="content heightfull">
        <div className="content-left-panel wdt-25">
          <SidebarNavigation customerType={customerType} />
        </div>
        <div className="content-middle-panel w-100 overflow-hidden">
          <div className="content-panel-header head multiple">
            Search Customer Report
          </div>
          <div className="content inline-height p-1 overflow-hidden">
            <div className="left_section">
              <div className="sub_header" style={{ borderRadius: "0.5rem" }}>
                <div className="flex_row">
                  <div className="dropdown_row">
                    <PzDropDown
                      options={contextSCR?.countryList}
                      onChange={onChangeRuleKey}
                      placeHolder="Select Country"
                      name="country"
                      value={contextSCR.ruleId.country}
                    />
                  </div>
                  <div className="dropdown_row">
                    <PzDropDown
                      options={eventTypeFullNameList}
                      onChange={onChangeRuleKey}
                      placeHolder="Select Event Type"
                      name="eventType"
                      value={contextSCR.ruleId.eventType}
                    />
                  </div>
                  <div className="dropdown_row">
                    <PzDropDown
                      options={poolList}
                      onChange={onChangeRuleKey}
                      placeHolder="Select Pool Type"
                      name="poolType"
                      value={contextSCR.ruleId.poolType}
                    />
                  </div>
                </div>
                <button
                  className="add_btn"
                  onClick={() => handleSearch(lazyParams)}
                >
                  <span className="fa fa-search icon"></span>
                  <span className="display_none">Search Customer</span>
                </button>
              </div>
              <div className="table-body">
                {contextSCR.searchCustomerReportList.length > 0 ? (
                  <DataTable
                    className="dataTable"
                    value={contextSCR.searchCustomerReportList}
                    paginator
                    rows={lazyParams.rows}
                    first={lazyParams.first}
                    totalRecords={totalRecords}
                    onPage={onPage}
                    lazy
                  >
                    {searchCustomerReportColumn.map((col, index) => {
                      return (
                        <Column
                          key={index}
                          style={{ width: col.width }}
                          field={col.field}
                          header={col.header}
                          body={col.field === "view" && viewTemplate}
                        />
                      );
                    })}
                  </DataTable>
                ) : (
                  <span className="empty_data">No Data Found!</span>
                )}
              </div>
            </div>
            <div className="right_section">
              <div className="heading" style={{ borderRadius: "0.5rem" }}>
                <h2 className="font-1">
                  {Object.keys(filterValueObj).length > 0
                    ? "Custom Filters"
                    : "Custom Filters"}
                </h2>
                <button
                  className="add_btn"
                  onClick={() => setIsSidebarOpen(true)}
                >
                  <span className="fa fa-plus icon"></span>
                  <span className="display_none">Add Filter</span>
                </button>
              </div>
              <div
                className="right_section_body"
                style={{ borderRadius: "0.5rem" }}
              >
                {Object.keys(filterValueObj).length === 0 && (
                  <span className="empty_data">No Filters Added </span>
                )}
                {Object.keys(filterValueObj).map((res, index) => {
                  return (
                    <div className="contain_filter" key={index}>
                      <span className="label">
                        {res} : {filterValueObj[res]}
                      </span>
                      <div className="d-flex">
                        <span
                          className="add-edit-btns"
                          onClick={() => onEditFilter(res, filterValueObj[res])}
                        >
                          <i className="fa fa-pencil"></i>
                        </span>
                        <span
                          className="add-edit-btns"
                          onClick={() => onHandleDeleteFilter(res)}
                        >
                          <i className="fa fa-trash"></i>
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
              {isSidebarOpen && <AddFunction {...addFunctionProps} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewSearchCustomerReport;
