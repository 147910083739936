import "./NextRaces.scss";
import { ActiveRaceDTO } from "../../Models/activeRaceDTO";
import TimerPostTime from "../Timers/TimerPostTime/timerPostTime";
import { LOCAL_STORAGE, ROUTES } from "../../Helpers/constants";
import { useHistory } from "react-router";
import { useContext } from "react";
import { AppContext } from "../../Context/appContext";

export interface INextRaceCardProps {
  race: ActiveRaceDTO;
}

export default function NextRaceCard({ race }: INextRaceCardProps) {
  const history = useHistory();
  const appContext = useContext(AppContext);

  const openRaceInSchedule = () => {
    localStorage.setItem(
      LOCAL_STORAGE.SELECTEDRACEKEY,
      JSON.stringify(race.raceKey)
    );
    let context = { ...appContext };
    context.race = race;
    context.updateContext(context);
    history.push(ROUTES.SCHEDULE);
  };

  return (
    <div onClick={openRaceInSchedule} className={"raceCard"}>
      <div>
        <div className="d-flex justify-content-between align-items-center">
          <b>
            {race.province}-{race.trackName}-{race.eventType}
          </b>

          <span className="raceNo"> R{race.raceKey.raceNo}</span>
        </div>
        <div className="bottomRow">
          <TimerPostTime
            key={race.raceKeyString}
            postTimeString={race.postTime}
            timeZone={race.timezone}
          />
          <button className="op-btn-2" title="Open race in schedule management">
            <span className="fa fa-external-link"></span>
          </button>
        </div>
      </div>
    </div>
  );
}
