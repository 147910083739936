import React, { useEffect, useState } from "react";
import LargeLoader from "../../../../UI-Components/Loader/loaders";
import Header from "../../../../UI-Components/Header/header";
import InsideTransactionHeader from "../InsideTransactionHeader";
import { Calendar } from "primereact/calendar";
import { showToast, TOAST } from "../../../../Helpers/ui-helper";
import { format } from "date-fns";
import { UserApiService } from "../../../../services/userApiService";
import { toteSummaryDTO } from "../../../../Models/TransactionDto";
import { DataTable } from "primereact/datatable";
import {
  toteSummaryDataList,
  WagersSummary_Header,
} from "../../../../Helpers/StaticData/transactionData";
import { Column } from "primereact/column";
import {
  getPostTimeFormat,
  getStringToDate,
  getStringToDateFormat,
  stringToStringDate,
} from "../../../../Helpers/dateTimeHelpers";
import "./wagersSummary.scss";
import { ImCheckmark, ImCross } from "react-icons/im";
import PzCheckBox from "../../../../UI-Components/Inputs/checkBoxInput";
import { Button } from "react-bootstrap";
import { getPoolKeyStr } from "../../../../Helpers/valueHelper";
import { ScheduleApiService } from "../../../../services/scheduleApiService";
import { DashboardService } from "../../../../services/dashboardService";
import { DateTimeFormatter, LocalDate } from "@js-joda/core";
import { DATE_FORMATTER, FORMAT } from "../../../../Helpers/constants";
import { CSVLink } from "react-csv";

const WagersSummary = () => {
  // variables
  // const [date, setDate] = useState<Date | undefined>();
  const [loading, setLoading] = useState(false);
  const [toteSummaryData, setToteSummaryData] = useState<any[]>([]);
  const [globalFilter, setGlobalFilter] = useState("" as string);
  const [exportCSV_Data, setExportCSV_Data] = useState<any[]>([]);
  const [dateRange, setDateRange] = useState<Date[]>([]);

  // functions
  const onDateChange = (e: any) => {
    if (!e.value) {
      showToast("Select Valid DateRange", TOAST.ERROR);
      return;
    }
    // console.log(e.value)
    setDateRange(e.value);
  };

  const handleFilter = (val: string) => {
    setGlobalFilter(val);
  };
  // useEffect(() => {handleBlockedChange(data)}, []);

  const handleBlockedChange = (data: any, rowIndex: number) => {
    data.provider = "DEFAULT";
    let _date = stringToStringDate(data.localDate, "dd-MM-yyyy", "yyyyMMdd");
    data.localDate = _date;
    let _poolKey = getPoolKeyStr(data);
    DashboardService.getUnblocked(_poolKey)
      .then((response) => {
        let _summary = [...toteSummaryData];
        _summary.splice(rowIndex, 1, response.data);
        _summary[rowIndex].localDate = getStringToDateFormat(
          response.data.poolKey.localDate.toString()
        );
        _summary[rowIndex].pzoneCode = response?.data?.poolKey?.pzoneCode;
        _summary[rowIndex].poolType = response?.data?.poolKey?.poolType;
        _summary[rowIndex].raceNo = response?.data?.poolKey?.raceNo;
        setToteSummaryData(_summary);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onCsvLink = () => {
    if (exportCSV_Data.length === 0) {
      return false;
    }
  };

  useEffect(() => {
    onExport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toteSummaryData]);



  const onExport = () => {
    let values: any = [...toteSummaryData];

    let wagersExportDataList: any = [];
    for (const val of values) {
      let wagersObj: any = {};
      wagersObj["raceNo"] = val.raceNo;
      wagersObj["localDate"] = val.localDate;
      wagersObj["pzoneCode"] = val.pzoneCode;
      wagersObj["poolType"] = val.poolType;
      wagersObj["blocked"] = val.blocked ? "true" : "false";
      wagersObj["resultProcessed"] = val.resultProcessed;
      wagersObj["payoffProcessed"] = val.payoffProcessed;
      
      wagersObj["totalLayoff"] = val.totalLayoff;
      wagersObj["totalInvestment"] = val.totalInvestment;
      wagersObj["totalLayoffInv"] = val.totalLayoffInv;
      wagersObj["totalPayoff"] = val.totalPayoff;

      wagersExportDataList.push(wagersObj);
    }
    setExportCSV_Data(wagersExportDataList);
  };

  

  const header = (
    <div className="d-flex justify-content-end">
      <div className="search-input">
        <input
          placeholder="Global Search"
          style={{ color: "#fff" }}
          type="search"
          value={globalFilter}
          onChange={(e: any) => handleFilter(e.target.value)}
        />
        <i className="fa fa-search icon"></i>
      </div>
      <div className="csvButton">
        <CSVLink
          data={exportCSV_Data}
          filename={
            format(dateRange[0] ?? new Date(), "yyyyMMdd") +
            "_" +
            format(dateRange[1] ?? new Date(), "yyyyMMdd") +
            "_" +
            "wagerSummary.csv"
          }
          className="pz-btn primary rounded tclass"
          target="_blank"
          headers={WagersSummary_Header}
          onClick={onCsvLink}
        >
          Export CSV
        </CSVLink>
      </div>
    </div>
  );

  let tsdate: string;
  let tedate: string;
  let sDateYear: number;
  let eDateYear: number;

  const onDateSet = () => {
    let tempDate: any = dateRange;
    if (tempDate[1] !== null && tempDate[0]) {
      sDateYear = tempDate[0].getFullYear();
      eDateYear = tempDate[1].getFullYear();
    }
    if (tempDate[0] && tempDate[1] === null) {
      sDateYear = tempDate[0].getFullYear();
      eDateYear = tempDate[0].getFullYear();
      tempDate[1] = tempDate[0];
    }
    if (sDateYear !== eDateYear) {
      console.log("selected Date Is Not okay");
      showToast("select same year date range", TOAST.ERROR);
      return;
    }

    tsdate = format(tempDate[0], "yyyyMMdd");
    tedate = format(tempDate[1], "yyyyMMdd");
  };

  const handleSubmit = () => {
    onDateSet();
    let tempDate: any = {
      startDate: Number(tsdate),
      endDate: Number(tedate),
    };

    setLoading(true);
    UserApiService.getToteSummaryByDate(tempDate)
      .then((res) => {
        if (res.data) {
          let toteSummaryData = res.data;
          let toteSummaryDataList = [] as any;
          for (let key of toteSummaryData) {
            let toteSummaryObj = {} as toteSummaryDTO;
            toteSummaryObj.localDate = getStringToDateFormat(
              key?.poolKey?.localDate?.toString()
            );

            toteSummaryObj.poolType = key?.poolKey?.poolType;
            toteSummaryObj.pzoneCode = key?.poolKey?.pzoneCode;
            toteSummaryObj.raceNo = key?.poolKey?.raceNo;
            toteSummaryObj.blocked = key?.blocked;
            toteSummaryObj.payoffProcessed = key?.payoffProcessed;
            toteSummaryObj.resultProcessed = key?.resultProcessed;
            toteSummaryObj.totalInvestment = key?.totalInvestment?.toFixed(2);
            toteSummaryObj.totalPayoff = key?.totalPayoff?.toFixed(2);
            toteSummaryObj.totalLayoff = key?.totalLayoff?.toFixed(2);
            toteSummaryObj.totalLayoffInv = key?.totalLayoffInv?.toFixed(2);

            toteSummaryDataList.push(toteSummaryObj);
          }

          setToteSummaryData(toteSummaryDataList);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        showToast(err?.response?.data?.message, TOAST.ERROR);
      });
  };

  const columnBody = (data: toteSummaryDTO, { field, rowIndex }: any) => {
    if (field === "resultProcessed") {
      if (data.resultProcessed) {
        return (
          <span className={"checkMarkSign"}>
            <ImCheckmark />
          </span>
        );
      }
      return (
        <span className={"checkMarkSign"}>
          {" "}
          <ImCross />
        </span>
      );
    }
    if (field === "payoffProcessed") {
      if (data.payoffProcessed) {
        return (
          <span className={"checkMarkSign"}>
            <ImCheckmark />
          </span>
        );
      }
      return (
        <span className={"checkMarkSign"}>
          {" "}
          <ImCross />
        </span>
      );
    }
    if (field === "blocked") {
      if (data.blocked) {
        return (
          <button
            onClick={() => handleBlockedChange(data, rowIndex)}
            className={`pz-btn primary`}
            style={{ marginLeft: "0.5rem" }}
          >
            UNBLOCK
          </button>
        );
      }
      return <span className={"checkMarkSign"}> --</span>;
    }

    return data[field];
  };

  return (
    <div className="bet_history_wrapper">
      <LargeLoader isLoading={loading} />
      <Header pageTitle="Reports" />
      <div className="content heightfull cont">
        <div className="content-left-panel sidebar">
          <InsideTransactionHeader selectionType="wagersSummary" />
        </div>
        <div className="content-middle-panel w-100">
          <div className="content-panel-header head multiple">
            Wagers Summary
          </div>
          <div
            className="inpage-nav m-3 flex-wrap"
            style={{ borderRadius: "0.5rem" }}
          >
            <div className="page-nav">
              <div className="action_bx">
                <Calendar
                  id="range"
                  onChange={onDateChange}
                  value={dateRange}
                  selectionMode="range"
                  monthNavigator
                  yearNavigator
                  yearRange="2020:3000"
                  placeholder="Select date range"
                  className="calendercss calender"
                />
              </div>
              <div>
                <button
                  onClick={() => handleSubmit()}
                  className={`pz-btn primary rounded wragerSumSubBtn`}
                  style={{ marginLeft: "0.5rem" }}
                >
                  SUBMIT
                </button>
              </div>
            </div>
          </div>
          <div className="pz-card">
            {toteSummaryData.length !== 0 ? (
              <DataTable
                className="pz-prime-table"
                value={toteSummaryData}
                paginator
                rows={15}
                header={header}
                globalFilter={globalFilter}
                emptyMessage="No Data found."
              >
                {toteSummaryDataList.map((col) => {
                  const { field, header } = col;
                  return (
                    <Column
                      sortable
                      key={field}
                      field={field}
                      header={header}
                      body={columnBody}
                    />
                  );
                })}
              </DataTable>
            ) : (
              <div className="no_data">
                <span>Data Not Available</span>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default WagersSummary;
