import { PoolRowDTO } from "../Models/BookDTO";
import { ResultsDTO } from "../Models/RaceDetailsDTO";
import { RaceTripDTO } from "../Models/raceTripsDTO";
import { CLOG } from "./ui-helper";

export const sortValuesAsc = (list: any[], key?: any) => {
  return list.sort((a: any, b: any) => {
    let adata = a[key];
    let bData = b[key];
    return adata > bData ? 1 : adata < bData ? -1 : 0;
  });
};

export const sortUsers = (users: any[], key: any) => {
  return users.sort((a: any, b: any) => {
    let adata = a.user[key].toLowerCase();
    let bData = b.user[key].toLowerCase();
    return adata > bData ? 1 : adata < bData ? -1 : 0;
  });
};

export const sortPoolRows = (rowData: PoolRowDTO[]) => {
  return rowData.sort((a, b) => {
    let data1 = Number(a.selId);
    let data2 = Number(b.selId);

    if (data1 < data2) return -1;
    else if (data1 > data2) return 1;

    return a.selId > b.selId ? 1 : a.selId < b.selId ? -1 : 0;
  });
};

export const sortRaceTrips = (trips: RaceTripDTO[]) => {
  return trips.sort((a, b) => {
    let data1 = Number(a.raceTripKey.selId);
    let data2 = Number(b.raceTripKey.selId);

    if (data1 < data2) return -1;
    else if (data1 > data2) return 1;

    return a.raceTripKey.selId > b.raceTripKey.selId
      ? 1
      : a.raceTripKey.selId < b.raceTripKey.selId
      ? -1
      : 0;
  });
};

export const resultedSort = (
  trips: RaceTripDTO[],
  results?: ResultsDTO,
  selectedRace?: any
) => {
  let winners: RaceTripDTO[] = [];
  let resultedRace: RaceTripDTO[] = [];

  if (results?.finishingOrder && selectedRace.raceStatus === "RESULTED") {
    let winIndex = trips.findIndex((x) => {
      return x?.raceTripKey?.selId === results?.finishingOrder[0];
    });
    winners[0] = trips[winIndex];

    trips.splice(winIndex, 1);
  } else {
  }

  let sortedTrips = trips.sort((a, b) => {
    let data1 = Number(a?.raceTripKey?.selId);
    let data2 = Number(b?.raceTripKey?.selId);

    if (data1 < data2) return -1;
    else if (data1 > data2) return 1;

    return a?.raceTripKey?.selId > b?.raceTripKey?.selId
      ? 1
      : a?.raceTripKey?.selId < b?.raceTripKey?.selId
      ? -1
      : 0;
  });

  resultedRace = [...winners, ...sortedTrips];

  return resultedRace;
};

export const findIndex = (list: any[], data: any, key?: string) => {
  if (key) return list.findIndex((x: any) => x[key] === data[key]);
  return list.findIndex((x: any) => x === data);
};

export const sortObject = (obj: any, key: string, sortKey: string) => {
  let sortedList: any[] = Object.values(obj).sort((a: any, b: any) => {
    return a[sortKey] < b[sortKey] ? -1 : a[sortKey] > b[sortKey] ? 1 : 0;
  });
  let sortedObj: any = {};
  for (let data of sortedList) {
    sortedObj[data[key]] = data;
  }
  return sortedObj;
};
