import Header from "../../../../UI-Components/Header/header";
import InsideTransactionHeader from "../InsideTransactionHeader";
import { useEffect, useRef, useState } from "react";
import { Calendar } from "primereact/calendar";
import "./CustomerBetHistoryData.scss";
import { showToast, TOAST } from "../../../../Helpers/ui-helper";
import { UserApiService } from "../../../../services/userApiService";
import {
  betHistoryRequest,
  betHistoryResponse,
  investMentData,
  payOutData,
} from "../../../../Models/TransactionDto";
import { format } from "date-fns";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import {
  clientColumns,
  customerCSV_Headers,
  investDataColumns,
  payoutColumns,
} from "../../../../Helpers/StaticData/transactionData";
import { Column } from "primereact/components/column/Column";
import { dateTimeConvert } from "../../../../Helpers/dateTimeHelpers";
import { Dialog } from "primereact/dialog";
import { numberFormate } from "../../../../Helpers/valueHelper";
import { AutoComplete } from "primereact/autocomplete";
import { ZoneId } from "@js-joda/core";
import "@js-joda/timezone";
import LargeLoader from "../../../../UI-Components/Loader/loaders";
import { options } from "../../../../Helpers/StaticData/pool-data-static";
import { CSVLink } from "react-csv";

export default function CustomerBetHistoryData() {
  const dt = useRef(null);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dateRange, setDateRange] = useState<Date[]>([]);
  const [betHistoryData, setBetHistoryData] = useState<betHistoryResponse[]>(
    []
  );
  const [successSelection, setSuccessSelection] = useState<number>();
  const [invData, setInvData] = useState<investMentData[]>([]);
  const [displayInvestData, setDisplayInvestData] = useState(false);
  const [displayPayOutData, setDisplayPayOutData] = useState(false);
  const [payOutData, setPayOutData] = useState<payOutData[]>([]);
  const [isDataNotAvailable, setIsDataNotAvailable] = useState(false);
  //filter Data for Client Search
  const [filterDataList, setFilterDataList] = useState([]);
  const [selectedClient, setSelectedClient] = useState<any>("");
  const [betOptions, setBetOptions] = useState<string>("");
  const [exportCSV_Data, setExportCSV_Data] = useState<any[]>([]);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 50,
    page: 1,
  });
  useEffect(() => {
    onExport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [betHistoryData]);

  const onExport = () => {
    let values: any = [...betHistoryData];

    console.log(values);

    let wagersExportDataList: any = [];
    for (const val of values) {
      let wagersObj: any = {};
      wagersObj["timestamp"] = val.timestamp;
      wagersObj["clientId"] = val.clientId;
      wagersObj["transactionId"] = val.transactionId;
      wagersObj["raceKey"] = val.raceKey;
      wagersObj["poolType"] = val.poolType;
      wagersObj["eventType"] = val.eventType;
      wagersObj["provider"] = val.provider;
      wagersObj["betStatus"] = val.betStatus;
      wagersObj["totalRefunds"] = val.totalRefunds;
      wagersObj["pnl"] = val.pnl;
      wagersObj["totalInvested"] = val.totalInvested;
      for (const t in val["poolPayout"]) {
        wagersObj["selection"] = t;
        wagersObj["selectionsOdds"] = val["selectionsOdds"][t];
        wagersObj["payoutSelection"] = val["poolPayout"][t] ? t : null;
        wagersObj["payoutOdds"] = val["poolPayout"][t]
          ? val["poolPayout"][t]
          : null;
        wagersObj["isResulted"] = val["poolPayout"][t] ? "payout" : null;
      }
      wagersExportDataList.push(wagersObj);
    }
    setExportCSV_Data(wagersExportDataList);
  };

  console.log(selectedClient);

  const onDateChange = (e: any) => {
    if (!e.value) {
      showToast("Select Valid DateRange", TOAST.ERROR);
      return;
    }
    console.log(e.value)
    setDateRange(e.value);
  };

  const handleKeyChange = (e: any) => {
    setBetOptions(e.target.value);
  };

  let tsdate: string;
  let tedate: string;
  let sDateYear: number;
  let eDateYear: number;

  const onDateSet = () => {
    let tempDate: any = dateRange;
    if (tempDate[1] !== null && tempDate[0]) {
      sDateYear = tempDate[0].getFullYear();
      eDateYear = tempDate[1].getFullYear();
    }
    if (tempDate[0] && tempDate[1] === null) {
      sDateYear = tempDate[0].getFullYear();
      eDateYear = tempDate[0].getFullYear();
      tempDate[1] = tempDate[0];
    }
    if (sDateYear !== eDateYear) {
      console.log("selected Date Is Not okay");
      showToast("select same year date range", TOAST.ERROR);
      return;
    }

    tsdate = format(tempDate[0], "yyyyMMdd");
    tedate = format(tempDate[1], "yyyyMMdd");
  };

  const setValidation = () => {
    if (!dateRange) {
      showToast("Select Date Range", TOAST.ERROR);
      return;
    }
    if (!selectedClient) {
      showToast("Please Select ClientId", TOAST.ERROR);
      return;
    }
    if (!betOptions) {
      showToast("Please Select Bet Options", TOAST.ERROR);
      return;
    }
    onDateSet();
  };

  const onSubmit = (_lazyParams: any, check: string) => {
    setLoading(true);

    if (check === "true") {
      let val = { ..._lazyParams };
      val = {
        first: 0,
        rows: 50,
        page: 1,
      };
      _lazyParams = val;
      setLazyParams(val);
    }
    setValidation();
    let requestBody: betHistoryRequest;
    requestBody = {
      betOptions: betOptions,
      startDate: Number(tsdate),
      endDate: Number(tedate),
      // localYear: sDateYear,
      historyType: "BET",
      customerId: selectedClient.value,
      size: _lazyParams.rows,
      pageNo: _lazyParams.page,
    };
    UserApiService.getCustomerHistoryData(requestBody)
      .then((res) => {
        if (res.data.totalRecords) {
          setTotalRecords(res.data.totalRecords);
        }
        setIsDataNotAvailable(false);
        for (const data of res.data.data) {
          data.timestamp = dateTimeConvert(data.timezone, data.timestamp);
        }
        if (res.data.data.length === 0) {
          setIsDataNotAvailable(true);
        }
        setBetHistoryData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setIsDataNotAvailable(true);
        console.log(err);
        if (err.response) showToast(err.response.data.message, TOAST.ERROR);
        else
          showToast("Server did not respond! Please try later!", TOAST.ERROR);
        setLoading(false);
      });
  };

  // const onExport = (_lazyParams: any, check: string) => {
  //   setLoading(true);
  //   if (check === "true") {
  //     let val = { ..._lazyParams };
  //     val = {
  //       first: 0,
  //       rows: 50,
  //       page: 1,
  //     };
  //     _lazyParams = val;
  //     setLazyParams(val);
  //   }
  //   onDateSet();
  //   let requestBody: betHistoryRequest;
  //   requestBody = {
  //     betOptions: betOptions,
  //     startDate: Number(tsdate),
  //     endDate: Number(tedate),
  //     // localYear: sDateYear,
  //     historyType: "BET",
  //     customerId: selectedClient.value,
  //     size: 1000,
  //     pageNo: _lazyParams.page,
  //     timezone: ZoneId.systemDefault().id(),
  //   };
  //   UserApiService.getCustomerDataForExort(requestBody)
  //     .then((res) => {

  //       console.log(res)
  //       let blob = new Blob([res.data], {
  //         type: "application/json, text/plain, */*",
  //       });
  //       console.log(blob)
  //       let a: any = document.createElement("a");
  //       document.body.appendChild(a);
  //       a.style = "display:none";
  //       let url = window.URL.createObjectURL(blob);

  //       a.href = url;
  //       a.download =
  //         tsdate + "_" + tedate + "_" + selectedClient.value + "_wagers.csv";

  //         console.log(a)
  //       a.click();
  //       window.URL.revokeObjectURL(url);
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       if (err.response) showToast(err.response.data.message, TOAST.ERROR);
  //       else
  //         showToast("Server did not respond! Please try later!", TOAST.ERROR);
  //       setLoading(false);
  //     });
  // };

  const onPayout = (rowData: any) => {
    let poolPayOutKeys = Object.keys(rowData.poolPayout);
    let poolpayOutMap = rowData.poolPayout;
    let tempList: payOutData[] = [];
    for (const key of poolPayOutKeys) {
      let tempObj: any = {};
      tempObj.selection = key;
      tempObj.odds = poolpayOutMap[key];
      tempList.push(tempObj);
    }
    setPayOutData(tempList);
    setDisplayPayOutData(true);
  };

  const onDataGet = (rowData: any, field: string) => {
    if (field === "isResulted") {
      if (
        rowData.betStatus === "RESULTED" &&
        Object.keys(rowData["poolPayout"]).length !== 0
      ) {
        return (
          <span
            onClick={() => onPayout(rowData)}
            className="sele-text text-capitalize"
          >
            payout
          </span>
        );
      }
    }
    if (field === "successBetAmt") {
      return (
        <span onClick={() => showInvestedData(rowData)} className="sele-text">
          {numberFormate(rowData?.successBetAmt)}
        </span>
      );
    }
    if (field === "pnl" || field === "totalWinnings") {
      if (rowData[field] !== null) {
        return numberFormate(rowData[field]);
      }
    }
    return <span>{rowData[field]}</span>;
  };

  const showInvestedData = (rowData: any) => {
    let selkeys: any = [];
    if (rowData.selectionsInvestment != null) {
      selkeys = Object.keys(rowData.selectionsInvestment);
      let selectionMap = rowData.selectionsInvestment;
      let combinationsTicketMap = rowData.correctCombinations;
      let tempList: investMentData[] = [];
      for (const key of selkeys) {
        let tempObj: any = {};
        tempObj.selection = key;
        tempObj.amount = selectionMap[key];
        tempObj.ticket = combinationsTicketMap[key];
        tempList.push(tempObj);
      }
      setInvData(tempList);
      setSuccessSelection(Object.keys(selectionMap).length);
      setDisplayInvestData(true);
    }
  };

  const investmentDataColumns = investDataColumns.map((col, i) => {
    return <Column key={col.field} field={col.field} header={col.header} />;
  });
  const payOutColumns = payoutColumns.map((col, i) => {
    return <Column key={col.field} field={col.field} header={col.header} />;
  });
  const onPage = (event: any) => {
    setLoading(true);
    let values = { ...lazyParams };
    values = {
      first: event.first,
      rows: event.rows,
      page: event.page + 1,
    };
    setLazyParams(values);
    onSubmit(values, "false");
  };

  const invFooterBtn = (
    <div className="btn-container">
      <button
        className="pz-btn primary footer_btn"
        onClick={() => setDisplayInvestData(false)}
      >
        Close
      </button>
    </div>
  );

  const onCsvLink = () => {
    if (exportCSV_Data.length === 0) {
      return false;
    }
  };

  const payOutFooterBtn = (
    <div className="btn-container">
      <button
        className="pz-btn primary footer_btn"
        onClick={() => setDisplayPayOutData(false)}
      >
        Close
      </button>
    </div>
  );

  const searchClient = (event: { query: string }) => {
    UserApiService.getClientFilteredData(event.query.trim())
      .then((res) => {
        if (res.data) {
          let tlist: any = [];
          for (const key of res.data) {
            let obj: any = {};
            obj["label"] = key.customerName;
            obj["value"] = key.customerKey.customerKey;
            tlist.push(obj);
          }
          setFilterDataList(tlist);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response) showToast(err.response.data.message, TOAST.ERROR);
        else
          showToast("Server did not respond! Please try later!", TOAST.ERROR);
      });
  };

  return (
    <div className="bet_history_wrapper">
      <LargeLoader isLoading={loading} />
      <Header pageTitle="Reports" />
      <div className="content heightfull cont">
        <div className="content-left-panel sidebar">
          <InsideTransactionHeader selectionType="CustomerBetHistoryData" />
        </div>
        <div className="content-middle-panel w-100">
          <div className="content-panel-header head multiple">
            Customer Wagers Data
          </div>
          <div
            className="inpage-nav m-3 flex-wrap"
            style={{ borderRadius: "0.5rem" }}
          >
            <div className="page-nav">
              <div className="action_bx betopt">
                <Dropdown
                  value={betOptions}
                  options={options}
                  name="betOptions"
                  onChange={handleKeyChange}
                  className="prime-drop"
                  placeholder="Select Bet Options"
                />
              </div>
              <div className="action_bx">
                <Calendar
                  id="range"
                  value={dateRange}
                  onChange={onDateChange}
                  selectionMode="range"
                  monthNavigator
                  yearNavigator
                  yearRange="2020:3000"
                  placeholder="Select Date Range"
                  className="calendercss calender"
                />
              </div>
              <div className="action_bx">
                <AutoComplete
                  value={selectedClient}
                  suggestions={filterDataList}
                  onChange={(e: any) => setSelectedClient(e.value)}
                  completeMethod={searchClient}
                  field="label"
                  className="calendercss calender"
                  placeholder="Search Customer Id"
                />
              </div>
              <div>
                <button
                  onClick={() => onSubmit(lazyParams, "true")}
                  className={`pz-btn primary rounded wagerCusHisSubBtn`}
                  style={{ marginLeft: "0.5rem" }}
                >
                  SUBMIT
                </button>
              </div>
            </div>
            {betHistoryData.length !== 0 && (
              <div className="csv_btn">
                <CSVLink
                  data={exportCSV_Data}
                  filename={
                    format(dateRange[0], "yyyyMMdd") +
                    "_" +
                    format(dateRange[1], "yyyyMMdd") +
                    "_" +
                    selectedClient.value +
                    "_" +
                    "CustomerWagerHistory.csv"
                  }
                  className="pz-btn primary rounded tclass"
                  target="_blank"
                  headers={customerCSV_Headers}
                  onClick={onCsvLink}
                >
                  Export CSV
                </CSVLink>
              </div>
            )}
          </div>

          {betHistoryData.length !== 0 && (
            <div className="pz-card">
              <div className="table-scroll">
                <DataTable
                  className="table-class"
                  ref={dt}
                  value={betHistoryData}
                  paginator
                  first={lazyParams.first}
                  rows={lazyParams.rows}
                  totalRecords={totalRecords}
                  lazy
                  onPage={onPage}
                >
                  {clientColumns.map((col, i) => {
                    const { field, header, width } = col;
                    return (
                      <Column
                        key={field}
                        field={field}
                        header={header}
                        style={{ width: width }}
                        body={(rowData) => onDataGet(rowData, field)}
                      />
                    );
                  })}
                </DataTable>
              </div>
            </div>
          )}
          <div>
            <Dialog
              header={`Success Selections :  ${successSelection}`}
              footer={invFooterBtn}
              visible={displayInvestData}
              className="dialog-class dark pool_payout_dailog"
              onHide={() => setDisplayInvestData(false)}
              draggable={false}
            >
              <DataTable value={invData} className="table-class ">
                {investmentDataColumns}
              </DataTable>
            </Dialog>
          </div>
          <div>
            <Dialog
              header="Pools Payout"
              footer={payOutFooterBtn}
              visible={displayPayOutData}
              className="dialog-class dark pool_payout_dailog"
              onHide={() => setDisplayPayOutData(false)}
              draggable={false}
            >
              <DataTable value={payOutData} className="table-class ">
                {payOutColumns}
              </DataTable>
            </Dialog>
          </div>
          <div className="pz-card">
            {isDataNotAvailable && (
              <div className="no_data">
                <span>Data Not Available!</span>
              </div>
            )}
            {betHistoryData.length === 0 && !isDataNotAvailable && (
              <div className="no_data">
                <span>Select Date Range and Customer Id</span>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}
