import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { AppContext, AppContextDTO } from "../../Context/appContext";
import { FORMAT, LOCAL_STORAGE, SERVER } from "../../Helpers/constants";
import { getPostTimeFormat } from "../../Helpers/dateTimeHelpers";
import { sortObject } from "../../Helpers/list-helpers";
import { CLOG, showAPIToast } from "../../Helpers/ui-helper";
import {
  getLocalStorageObject,
  getTrackName,
  raceKeySplit,
} from "../../Helpers/valueHelper";
import { ActiveRaceDTO } from "../../Models/activeRaceDTO";
import { JWTResponseDTO } from "../../Models/UserManagementDto";
import { DashboardService } from "../../services/dashboardService";
import { upcRacesAtom } from "../../store/scheduleManagement/races.store";
import "./NextRaces.scss";
import NextRaceCard from "./raceCard";

export interface NextRacesProps {}
var ws: WebSocket | null;

export const NextRaces = (props: NextRacesProps) => {
  // const [upcRaces, setUpcRaces] = useState<any[] | null>(null);
  const refRace = useRef<HTMLDivElement>(null);
  const {user} = useContext(AppContext)


  const [upcRaces, setUpcRaces] = useRecoilState(upcRacesAtom);

  const getRaces = useCallback((races: any) => {
    for (let raceKey in races) {
      let _race = races[raceKey] as ActiveRaceDTO;
      _race.eventType = raceKeySplit(_race.raceKey.pzoneCode, "-", 2);
      _race.trackName = getTrackName(_race.raceKey.pzoneCode);
      _race.province = raceKeySplit(_race.raceKey.pzoneCode, "-", 0);
      _race.raceKeyString = raceKey;
      _race.localPostTime = Number(
        getPostTimeFormat(_race.timezone, _race.postTime, FORMAT.yyyyMMddHHmmss)
      );
    }
    return races;
  }, []);

  useEffect(() => {
    const listenToDashSocket = () => {
      let jwt = getLocalStorageObject(LOCAL_STORAGE.JWT) as JWTResponseDTO;
      if (!jwt) return;
      if (ws && ws.readyState === WebSocket.OPEN) {
        ws.close();
      }

      let url = SERVER.WS_URL_RACEPOOLS + "dashboard?token=" + jwt.token;
      ws = new WebSocket(url);

      ws.onopen = () => {
        CLOG("__WSO__DASH SOCKET OPEN___", "green");
      };

      ws.onerror = (e: any) => {
        console.error("SOCKET ERROR => ", e);
      };

      ws.onclose = () => {
        CLOG("__WSC__DASH SOCKET CLOSED___", "red");
      };

      ws.onmessage = (e: any) => {
        if (e?.data) {
          let data = JSON.parse(e.data);

          switch (data.messageType) {
            case "ACTIVE_RACES":
              let racesObj = getRaces(data.msg);
              let sortedObj = sortObject(
                racesObj,
                "raceKeyString",
                "localPostTime"
              );
              setUpcRaces(sortedObj);
              break;
            default:
              break;
          }
        }
      };
    };
    if (!ws && !upcRaces) {
      listenToDashSocket();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upcRaces]);

  useEffect(() => {
  //   const fetchActiveRace = () => {
  //     DashboardService.getActiveRaces()
  //       .then((res) => {
  //         if (res.data) {
  //           let racesObj = getRaces(res.data);
  //           let sortedRaces = sortObject(
  //             racesObj,
  //             "raceKeyString",
  //             "localPostTime"
  //           );

  //           console.log(sortedRaces);
  //           setUpcRaces(sortedRaces);

  //           console.log("file 1");
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         showAPIToast(err, "while fetching active races", "act-races");
  //       });
  //   };

  //  if(user)fetchActiveRace();

    return () => {
      CLOG("DESTROY", "#7845ff");
      if (ws) {
        ws.close();
        ws = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scrollElement = (isNagative?: boolean) => {
    let offSet = 230;
    let val = isNagative ? -offSet : +offSet;
    if (refRace.current) refRace.current.scrollLeft += val;
  };

  return (
    <div className="nextRacesWrapper">
      <div className="nextRaces">
        <div className="arrowButtons">
          <button onClick={() => scrollElement(true)}>
            <span className="fa fa-angle-left"></span>
          </button>
        </div>
        <div className="races" ref={refRace}>
          {Object.values(upcRaces ?? {}).map((race: any, index) => {
            return (
              <NextRaceCard key={race.raceKeyString ?? index} race={race} />
            );
          })}
        </div>
        <div className="arrowButtons">
          <button onClick={() => scrollElement()}>
            <span className="fa fa-angle-right"></span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default NextRaces;
