import React from "react";
import { TableColumnsDTO } from "../Helpers/columns";
import { PrimeItem } from "../Helpers/StaticData/race-data";
import { RaceTripDTO } from "../Models/raceTripsDTO";
import { PoolDTO } from "../Models/PoolsDTO";
import { TrackRacesDTO } from "../Models/trackwiseRacesDTO";
import { ResultsDTO } from "../Models/RaceDetailsDTO";


export interface ScheduleContextDTO {
  poolToEdit: any;
  selectedRace: any;
  selectedRaceTrips: RaceTripDTO[];
  provinces: string[];
  isLoading: boolean;
  selectedDate: Date;
  racePoolsObj: any;
  runnerFields: any;
  scrProfileList: any[];
  runnerFieldCols: TableColumnsDTO[];
  usersList: PrimeItem[];
  userData: any;
  selectedTrack: TrackRacesDTO;
  connectorsOptions: any;
  totalRaces: any;
  setConnectorOptions: (list: any) => void;
  setPools: (pools: PoolDTO[]) => void;
  setPoolToEdit: (editpool: any) => void;
  setSelectedRace: (race: any) => void;

  setSelectedTrack: (track: TrackRacesDTO) => void;
  startLoader: () => void;
  stopLoader: () => void;
  togglePoolForm: (state: boolean) => void;
  setSelectedRaceTrips: (selectedRaceTrips: RaceTripDTO[]) => void;

  setRunnerFields: (runnerField: any) => void;
  setRunnerFiledsCols: (runnerFiledCols: TableColumnsDTO[]) => void;
}

export const ScheduleContext = React.createContext<ScheduleContextDTO>(
  {} as ScheduleContextDTO
);

// export const resultContext = React.createContext<ResultsDTO>(
//   {} as ResultsDTO
// )

export const ScheduleContextProvider = ScheduleContext.Provider;

export const ScheduleContextConsumer = ScheduleContext.Consumer;
