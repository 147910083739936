import { useState } from "react";
import {
  RulesContextDTO,
  RulesContextProvider,
} from "../../Context/rulesContext";
import Header from "../../UI-Components/Header/header";
import LargeLoader from "../../UI-Components/Loader/loaders";
import ToteRules from "./Components/ToteRulesPage/toteRules";
import ScratchProfile from "./Components/ScratchProfile/scratchProfile";
import "./rulesManagment.scss";
import CustomerRules from "./Components/CustomerRules/CustomerRules";

export interface IRulesManagmentPageProps {}

enum Page {
  TOTE,
  SCR,
  ODDS,
  CONNECTOR,
  CUSTOMER,
  ODDS_PROFILE,
  CUSTOMER_RULES,
}

const P_PAGES = [
  { title: "Tote Pool Rules", value: Page.TOTE },
  { title: "Scratch Profile", value: Page.SCR },
  { title: "Customer Rules", value: Page.CUSTOMER_RULES },
];

export default function RulesManagmentPage(props: IRulesManagmentPageProps) {
  const [selectedPage, setSelectedPage] = useState<Page>(Page.TOTE);
  const [ruleContext, setRuleContext] = useState({} as RulesContextDTO);

  const changeSelectedPage = (page: Page) => {
    setSelectedPage(page);
  };

  const PageTitles = () => {
    return P_PAGES.map((page) => {
      return (
        <button
          onClick={() => changeSelectedPage(page.value)}
          key={page.value}
          className={page.value === selectedPage ? "nav-btn active" : "nav-btn"}
        >
          <span>{page.title}</span>
          <i className="fa fa-caret-right"></i>
        </button>
      );
    });
  };

  const selectedPageTemplate = () => {
    switch (selectedPage) {
      case Page.TOTE:
        return <ToteRules />;
      case Page.SCR:
        return <ScratchProfile />;
      case Page.CUSTOMER_RULES:
        return <CustomerRules />;     
    }
  };

  const updateContext = (ctx: RulesContextDTO) => setRuleContext(ctx);
  const startLoader = () => {
    let context = { ...ruleContext };
    context.isLoading = true;
    setRuleContext(context);
  };
  const stopLoader = () => {
    let context = { ...ruleContext };
    context.isLoading = false;
    setRuleContext(context);
  };

  return (
    <RulesContextProvider
      value={{
        ...ruleContext,
        updateRuleContext: updateContext,
        startLoader: startLoader,
        stopLoader: stopLoader,
      }}
    >
      <Header pageTitle="Rules Management" />
      <LargeLoader isLoading={ruleContext.isLoading} />
      <div className="content heightfull">
        <div className="content-left-panel wdt-25">
          <div className="content-panel-header head multiple">Rule Profile</div>
          <div className="side-nav">{PageTitles()}</div>
        </div>
        <div className="content-middle-panel clp">{selectedPageTemplate()}</div>
      </div>
      {/* <Footer /> */}
    </RulesContextProvider>
  );
}
